import { Component, createRef, Fragment } from "react";
import { observer } from "mobx-react";
import { toJS, reaction } from "mobx";
import { Box, Grid } from "@mui/material";
import { WithLogic, withLogicContext } from "../../App";
import {
  DBTableStamps,
  defaultPageTitle,
  devCountry,
  developersActions,
  imageDimensions,
  layoutUsageWidth,
  masonrySpacing,
  seoTitleSuffix,
  stampWidth,
} from "../../configs";
import StampSidePanel from "../../components/StampSidePanel";
import { getFileNameFromUrl } from "../../utils/string-utils";
import StampSneak from "../../components/StampSneak";
import DevelopersActions from "../../components/DevelopersActions";
import StampDetail from "../../components/StampDetail";
import ImageDimensions from "../../components/ImageDimensions";
import {
  DocumentReference,
  collection,
  getDocs,
  getFirestore,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import Loading from "../../components/Loading";
import Footer from "../../components/Footer";
import { Masonry } from "@mui/lab";
import { Helmet } from "react-helmet-async";
import Story from "../../components/Story";
import ThemeHeading from "../../components/ThemeHeading";
import CenterBoxWrapper from "../../components/CenterBoxWrapper";
import { FilterParamsModel } from "../../types/operations";
import menutItems from "../../settings/xmenu.json";
import NavbarModule from "../navbar/NavbarModule";
import { Tag } from "@mui/icons-material";

export enum Color {
  Red = "red",
  Yellow = "yellow",
  Green = "green",
  Blue = "blue",
  Purple = "purple",
  Orange = "orange",
  Black = "black",
}

export enum RouteParamsList {
  Tag = "theme",
  Country = "country",
  Hash = "hash",
  Stamp = "stamp",
}

interface RouteParams {
  list?: RouteParamsList;
  tag?: string;
  stamp?: string;
}

interface Props extends WithLogic {
  routeParams?: RouteParams;
}

interface State {
  filter?: {
    color?: Color;
    year: {
      start: number;
      end: number;
    };
  };
  openIds: string[];
  mapOpen: boolean;
  openCollectionWords: boolean;
  resizing: boolean;
  columns: number;
  hoverId?: string;
  tags?: {
    [key: string]: string;
  };
}

class PlaygroundModule extends Component<Props, State> {
  private resizeTimeout: NodeJS.Timeout | null = null;
  private myRef: React.RefObject<HTMLDivElement>;
  private isInitialMount: boolean;
  private map: google.maps.Map | null = null;
  private markers: google.maps.Marker[] = [];

  constructor(props: Props) {
    super(props);
    this.isInitialMount = true;
    this.myRef = createRef();

    this.state = {
      openIds: [],
      openCollectionWords: false,
      resizing: false,
      columns: this.calculateColumns(),
      mapOpen: false,
    };

    this.resizeTimeout = null;
  }

  async componentDidMount() {
    if (this.isInitialMount) {
      this.isInitialMount = false;
      console.log("Component mounted once");

      const tag = this.props.routeParams?.tag;
      // remove everything after ? in tag, together with ? and save it as tag
      if (tag) {
        const tagParts = tag.split("?");
        this.props.logic.playground().init({
          country: devCountry,
          tag: tagParts[0].replaceAll("-", "_"),
          stamp: this.props.routeParams?.stamp,
          object: this.props.routeParams?.list,
          id: tagParts[0].replaceAll("-", "_"),
        });
      } else {
        // Your one-time logic here (e.g., API calls, subscriptions)
        this.props.logic.playground().init({
          country: devCountry,
          tag: this.props.routeParams?.tag?.replaceAll("-", "_"),
          stamp: this.props.routeParams?.stamp,
          object: this.props.routeParams?.list,
          id: this.props.routeParams?.tag?.replaceAll("-", "_"),
        });
      }

      this.initMap();
    }
    window.addEventListener("resize", this.handleResizeDebounced);

    // Add observer for changes in stamps
    reaction(
      () => toJS(this.props.logic.playground().stamps),
      () => {
        this.reloadMapData();
      }
    );

    reaction(
      () => toJS(this.props.logic.playground().tagPreviewed),
      () => {
        this.setState({
          mapOpen: false,
        });
      }
    );
  }

  private reloadMapData = () => {
    if (this.state.mapOpen) {
      this.updateMapMarkers();
    }
  };

  initMap = () => {
    if (typeof window.google === "undefined") {
      console.error("Google Maps JavaScript API not loaded.");
      return;
    }

    const mapOptions = {
      zoom: 2,
      navigationControl: true,
      disableDefaultUI: false,
      mapTypeControl: false,
      scaleControl: false,
      draggable: true,
      scrollwheel: true,
      disableDoubleClickZoom: true,
      gestureHandling: "cooperative",
      center: new google.maps.LatLng(26, 20),
      styles: [],
      streetViewControl: false,
      fullscreenControl: false,
      minZoom: 2,
      restriction: {
        latLngBounds: {
          north: 85,
          south: -85,
          west: -180,
          east: 180,
        },
      },
    };

    const mapElement = document.getElementById("map");
    if (mapElement) {
      this.map = new google.maps.Map(mapElement, mapOptions);
      this.updateMapMarkers();
    } else {
      console.error("Map element not found.");
    }
  };

  updateMapMarkers = () => {
    if (!this.map) {
      return;
    }

    // Clear existing markers
    this.markers.forEach((marker) => marker.setMap(null));
    this.markers = [];

    console.log(
      "UPDARTE MAP",
      toJS(
        this.props.logic
          .playground()
          .stampsMap.filter((stamp) =>
            stamp.geometa?.find(
              (d) =>
                d.type ===
                (this.props.logic.playground().tagPreviewed?.tag_id ?? "")
            )
          )
      )
    );

    const locations: [string, string, number, number][] = this.props.logic
      .playground()
      .stampsMap.filter((stamp) =>
        stamp.geometa?.find(
          (d) =>
            d.type ===
            (this.props.logic.playground().tagPreviewed?.tag_id ?? "")
        )
      )
      .map((stamp, i) => [
        stamp._id ?? "",
        stamp.name ?? "",
        stamp.geometa?.find(
          (d) =>
            d.type ===
            (this.props.logic.playground().tagPreviewed?.tag_id ?? "")
        )?.location?._lat ?? 0,
        stamp.geometa?.find(
          (d) =>
            d.type ===
            (this.props.logic.playground().tagPreviewed?.tag_id ?? "")
        )?.location?._long ?? 0,
      ]);

    const icon = {
      // url: "http://www.wearestamps.net/images/pointer.png",
      url: "images/pin_navy.png",
      origin: new google.maps.Point(0, 0),
    };
    const that = this;

    for (let i = 0; i < locations.length; i++) {
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(
          locations[i][2] as number,
          locations[i][3] as number
        ),
        // icon: icon,
        map: this.map,
      });
      google.maps.event.addListener(
        marker,
        "click",
        ((marker, i) => {
          return function () {
            //   infowindow.setContent(locations[i][0]);
            that._onStampImageClick(locations[i][0]);
            //   infowindow.open(map, marker);
          };
        })(marker, i)
      );
      this.markers.push(marker);
    }
  };

  async componentDidUpdate(prevProps: Props) {
    console.log("CDU", prevProps);
    this.executeScroll();
  }

  executeScroll = () => {
    this.myRef && this.myRef.current?.scrollIntoView();
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResizeDebounced);
  }

  calculateColumns = (): number => {
    const pageWidth = document.documentElement.clientWidth;
    const columns = Math.min(
      Math.floor((layoutUsageWidth * pageWidth) / stampWidth),
      5
    );
    console.log(
      "calculateColumns",
      columns + "= ",
      layoutUsageWidth,
      pageWidth,
      stampWidth
    );
    return columns;
  };

  handleResizeDebounced = () => {
    this.setState({
      resizing: true,
    });
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }

    this.resizeTimeout = setTimeout(() => {
      this.setState({
        columns: this.calculateColumns(),
        resizing: false,
      });
    }, 500);
  };

  render() {
    const cannonicalUrl = window.location.href;
    const theme = this.props.logic
      .playground()
      .tags.find(
        (d) => d.tag_id === this.props.logic.playground().tagPreviewed?.tag_id
      )?.label;

    let childrenThemes: string[] = [];
    if (theme) {
      menutItems.forEach((item) => {
        item.children?.forEach((child) => {
          if (
            child.tag === this.props.logic.playground().tagPreviewed?.tag_id
          ) {
            childrenThemes = child.children.map((d) => d.tag);
          }
        });
      });
    }

    const actualChildThemes = this.props.logic
      .playground()
      .tags.filter((tag) => tag.tag_id && childrenThemes.includes(tag.tag_id));

    const pageTitle = theme ? "Theme: " + theme : defaultPageTitle;

    const playgroundWidth =
      this.props.logic.playground().stamps.length > this.state.columns
        ? this.state.columns * stampWidth +
          (this.state.columns - 1) * masonrySpacing * 8
        : undefined;

    const sectionWidth =
      this.state.columns * stampWidth +
      (this.state.columns - 1) * masonrySpacing * 8;

    return (
      <>
        <Box className="App">
          <NavbarModule />
        </Box>
        <div className="res-ref" ref={this.myRef} />
        {/* <CollectionWords
          open={this.props.logic.playground().isOpenCollectionWords}
          onCloseClick={this._onCollectionWordsCloseClick}
          onChipClick={this._onCollectionWordsChipClick}
          onWordsLeave={this._onCollectionWordsCloseClick}
        /> */}

        {this.props.logic.playground().noMatchStamp && (
          <CenterBoxWrapper width={300}>
            <Box sx={{ textAlign: "center" }}>No stamps found</Box>
          </CenterBoxWrapper>
        )}

        <Grid container>
          <Grid item xs={12}>
            {theme && !this.props.logic.playground().stampDetailed && (
              <CenterBoxWrapper width={sectionWidth}>
                <ThemeHeading
                  onMapToggle={
                    this.props.logic.playground().tagPreviewed?.geolocated ===
                    true
                      ? this._onMapToggle
                      : undefined
                  }
                />
              </CenterBoxWrapper>
            )}
            {this.state.mapOpen &&
              this.props.logic.playground().tagPreviewed?.geolocated && (
                <CenterBoxWrapper width={playgroundWidth ? playgroundWidth : 0}>
                  <Box
                    style={
                      this.props.logic.playground().stampDetailed
                        ? {
                            position: "absolute",
                            visibility: "hidden",
                          }
                        : {}
                    }
                  >
                    <Grid item xs={12}>
                      <div className="map">
                        <div
                          id="map"
                          style={{
                            height: "calc(100vh - 400px)",
                            width: playgroundWidth,
                          }}
                        />
                      </div>
                    </Grid>
                  </Box>
                </CenterBoxWrapper>
              )}

            {this.props.logic.playground().stampDetailed ? (
              imageDimensions ? (
                <ImageDimensions
                  onSaveDimensions={
                    this.props.logic.playground().saveDimensions
                  }
                />
              ) : (
                <>
                  <StampDetail
                    columns={this.state.columns}
                    stamp={this.props.logic.playground().stampDetailed}
                    onButtonBackClick={this._onButtonBackClick}
                    onChipClick={this._onCollectionWordsChipClick}
                    onStampSneakDevAddCollectionId={this._onDevAddCollectionId}
                    onStampSneakDevAddOmnibusId={this._onDevAddOmnibusId}
                    onCollectionImageClick={this._onStampCollectionImageClick}
                    onOmnibusImageClick={this._onStampOmnibusImageClick}
                    onLoadTagsClick={this._onLoadTagsClick}
                    onDevAddThemeTag={this._addThemeTag}
                    onDevAddLabel={this._addLabel}
                    onDevChangeExtension={this._onChangeExtension}
                    onDevBiometa={this.props.logic.playground().onDevBiometa}
                  />
                  <CenterBoxWrapper width={sectionWidth}>
                    <Footer
                      columns={this.state.columns}
                      withAboutUs={false}
                      withCountries={false}
                    />
                  </CenterBoxWrapper>
                </>
              )
            ) : (
              <>
                <Helmet>
                  <title>{pageTitle + seoTitleSuffix}</title>
                  <link rel="canonical" href={`${cannonicalUrl}`} />
                </Helmet>
                <div className="playground-module">
                  <>
                    {(this.props.logic.playground().loading ||
                      this.state.resizing) && <Loading />}

                    {this.props.logic.playground().list === "story" ? (
                      <Story />
                    ) : (
                      <Box
                        className="results"
                        sx={{
                          marginTop: `${
                            this.props.logic.playground().loading
                              ? "calc(-100% - 15px)"
                              : "0"
                          }`,
                        }}
                      >
                        <Masonry
                          columns={Math.min(
                            this.state.columns,
                            this.props.logic.playground().stamps.length
                          )}
                          spacing={masonrySpacing}
                          style={{
                            // width: "auto",
                            width: playgroundWidth ? playgroundWidth : "100%",
                          }}
                        >
                          {this.props.logic
                            .playground()
                            .stamps.filter(
                              (stamp) =>
                                stamp.format === "Stamp" &&
                                stamp._disabledImg !== true
                            )
                            .map((stamp, index) => (
                              <Fragment key={stamp._id}>
                                {actualChildThemes.length > 0 &&
                                  index === this.state.columns - 1 && (
                                    <nav className="sneak-menu">
                                      <ul className="mega-menu mega-menu--multiLevel">
                                        {actualChildThemes.map((tag) => (
                                          <li key={tag.tag_id}>
                                            <a
                                              href={`#${tag.tag_id}`}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this._onCollectionWordsChipClick(
                                                  tag.tag_id
                                                );
                                              }}
                                              className="menu-link mega-menu-link"
                                            >
                                              {tag.label.toUpperCase()}
                                              {tag.tag_id.startsWith("wog") &&
                                                " ❄️"}
                                              {tag.tag_id.startsWith("og") &&
                                                " 🌟"}
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </nav>
                                  )}

                                <div key={index}>
                                  <StampSneak
                                    index={index}
                                    isLandingPage={
                                      this.props.logic.playground().landing
                                    }
                                    key={index}
                                    stamp={stamp}
                                    onDevAddThemeTag={this._addThemeTag}
                                    onDevAddColor={
                                      this.props.logic.playground().addColor
                                    }
                                    onDevAddCollectionId={
                                      this._onDevAddCollectionId
                                    }
                                    onDevAddOmnibusId={this._onDevAddOmnibusId}
                                    onImageClick={
                                      this._onStampSneakDetailButtonClick
                                    }
                                    // onImageClick={this._onStampImageClick}
                                    onAddToFavouritesButtonClick={
                                      this._onAddToFavouritesButtonClick
                                    }
                                    onAddToAlbumButtonClick={
                                      this._onAddToAlbumButtonClick
                                    }
                                    onRemoveFromAlbumButtonClick={
                                      this._onRemoveFromAlbumButtonClick
                                    }
                                    onDetailButtonClick={
                                      this._onStampImageClick
                                    }
                                    onDevChangeWidthHeight={
                                      this._onChangeWidthHeight
                                    }
                                    onDevChangeExtension={
                                      this._onChangeExtension
                                    }
                                    onLoadTagsClick={this._onLoadTagsClick}
                                    onDevAddLabel={this._addLabel}
                                    onNameChange={
                                      this.props.logic.playground()
                                        .onStampNameUpdate
                                    }
                                    onDevGeolocation={
                                      this.props.logic.playground()
                                        .onDevGeolocation
                                    }
                                    onDevBiometa={
                                      this.props.logic.playground().onDevBiometa
                                    }
                                  />
                                </div>
                              </Fragment>
                            ))}
                        </Masonry>
                      </Box>
                    )}
                  </>
                  {theme && !this.props.logic.playground().stampDetailed && (
                    <CenterBoxWrapper width={sectionWidth}>
                      <ThemeHeading
                        onMapToggle={
                          this.props.logic.playground().tagPreviewed?.geolocated
                            ? this._onMapToggle
                            : undefined
                        }
                      />
                    </CenterBoxWrapper>
                  )}
                  <CenterBoxWrapper width={sectionWidth}>
                    <Footer
                      columns={this.state.columns}
                      withAboutUs={true}
                      withCountries={true}
                    />
                  </CenterBoxWrapper>
                </div>
                <StampSidePanel
                  stampsDetailed={this.props.logic.playground().stampsPreviewed}
                  onStampDetailCloseClick={this._onStampPreviewCloseClick}
                  tag={this.props.logic.playground().tagPreviewed?.tag_id ?? ""}
                />
                {developersActions && (
                  <DevelopersActions
                    onDuplicateParameterToConsoleString={
                      this._duplicateParameterToConsoleString
                    }
                    onExportJSONImgToString={this._exportJSONImgToString}
                    onAddJSONToDbClick={this._addJSONToDb}
                    onExportJSONClick={this._exportJSON}
                    onFetchEnNameForSkClick={this._fetchEnNameForSk}
                    onAddTimestampClick={this._onAddTimestampClick}
                    onCopyIdTo_IdClick={this._onCopyIdTo_IdClick}
                    onRenameColumnClick={this._onRenameColumnClick}
                    onDeleteYearCountry={
                      this.props.logic.playground().onDeleteYearCountry
                    }
                    operationTags={this.props.logic.playground().operationTags}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      </>
    );
  }

  private _onMapToggle = () => {
    this.setState(
      {
        mapOpen: !this.state.mapOpen,
      },
      () => {
        this.state.mapOpen && this.initMap();
      }
    );
  };

  private _addThemeTag = (tag: string, stamp_id?: string) => {
    stamp_id &&
      this.props.logic.playground().addThemeTagSeparate(stamp_id, tag);

    stamp_id &&
      this.props.logic.playground().updateStampThemeTags(stamp_id, tag);
  };

  private _addLabel = (label: string, stamp_id?: string) => {
    stamp_id && this.props.logic.playground().addLabel(label, stamp_id);
  };

  private _duplicateParameterToConsoleString = () => {
    this.props.logic.playground().duplicateParameterToConsoleString();
  };

  private _exportJSONImgToString = () => {
    this.props.logic.playground().exportJSONImgToString();
  };

  private _addJSONToDb = () => {
    this.props.logic.playground().addJSONToDb();
  };

  private _onReloadClick = (hash: string) => {
    this.props.logic.playground().reload(hash);
  };

  private _onAddTimestampClick = () => {
    this.props.logic.playground().addTimestampField();
  };

  private _onCopyIdTo_IdClick = () => {
    this.props.logic.playground().onCopyIdTo_IdClick();
  };

  private _onRenameColumnClick = () => {
    this.props.logic.playground().onRenameColumnClick();
  };

  //   private _createXGeopoint = () => {
  //     // this.props.logic.playground().createXGeopoint();
  //   };

  private _fetchEnNameForSk = () => {
    const stamp = this.props.logic.playground().stamps[0];

    const enUrl = stamp.urlEn;

    if (enUrl) {
      fetch(enUrl)
        .then((res) => res.text())
        .then(
          (_file) => {
            // const { parse } = require("node-html-parser");
            // const root = parse(file);
            // const text = root.querySelector(".basic-info .title").text;
          },
          (error) => {
            console.log("Error: ", error);
          }
        );
    }
  };

  private _exportJSON = () => {
    const x = this.props.logic.playground().stamps.map((stamp) => ({
      ...stamp,
      _id: stamp.id,
      imgLocal: getFileNameFromUrl(stamp.img ?? ""),
    }));
    console.log(JSON.stringify(x));
  };

  private _onStampPreviewCloseClick = () => {
    this.props.logic.playground().togglePreview();
  };

  // private _onCollectionWordsCloseClick = () => {
  //   this.props.logic.playground().toggleCollectionWords();
  // };

  private _onCollectionWordsChipClick = (code: string) => {
    // const path = window.location.hash.split("/")[1] ?? "";
    this.props.logic
      .playground()
      .loadStamps({ tag: code, page: 0 } as FilterParamsModel);
  };

  private _onAddToFavouritesButtonClick = () => {};

  private _onAddToAlbumButtonClick = () => {};

  private _onRemoveFromAlbumButtonClick = () => {};

  private _onStampImageClick = (stampId?: string) => {
    console.log("KLIK", stampId);
    if (stampId) {
      console.log("klik2");
      const currentGeoLocation = this.props.logic
        .playground()
        .stampsMap.find((d) => d._id === stampId)
        ?.geometa?.find(
          (d) =>
            d.type ===
            (this.props.logic.playground().tagPreviewed?.tag_id ?? "")
        )?.location;

      // stamps with same geoLocation
      console.log("klik", toJS(currentGeoLocation));
      const geoLocatedStamps = this.props.logic
        .playground()
        .stampsMap.filter(
          (d) =>
            d.geometa?.find(
              (d) =>
                d.type ===
                (this.props.logic.playground().tagPreviewed?.tag_id ?? "")
            )?.location?._lat === currentGeoLocation?._lat &&
            d.geometa?.find(
              (d) =>
                d.type ===
                (this.props.logic.playground().tagPreviewed?.tag_id ?? "")
            )?.location?._long === currentGeoLocation?._long
        )
        .map((stamp) => stamp._id)
        .filter((id): id is string => id !== undefined); // Ensure only defined strings are passed

      console.log("klik geoLocatedStamps", geoLocatedStamps);

      this.props.logic.playground().togglePreview(geoLocatedStamps);
    }
  };

  private _onLoadTagsClick = (stampId?: string) => {
    if (stampId) {
      this.props.logic.playground().loadTags(stampId);
    }
  };

  private _onStampCollectionImageClick = (stampId?: string) => {
    if (stampId) {
      this.props.logic.playground().onCollectionImageClick(stampId);
    }
  };

  private _onStampOmnibusImageClick = (stampId?: string) => {
    if (stampId) {
      this.props.logic.playground().onOmnibusImageClick(stampId);
    }
  };

  private _onDevAddOmnibusId = async (omnibusId: string, id: string) => {
    if (omnibusId !== "" && id !== "") {
      const db = getFirestore();
      const colRef = collection(db, DBTableStamps);
      const q = query(colRef, where("_id", "==", id));
      const querySnapshot = await getDocs(q);
      const batch = writeBatch(db);
      querySnapshot.forEach((doc) => {
        const docRef: DocumentReference = doc.ref;

        batch.update(docRef, {
          omnibusId: omnibusId,
        });
      });

      console.log("OMNIBUS_ID changed", id);
      await batch.commit();
    }
  };

  private _onDevAddCollectionId = async (collectionId: string, id: string) => {
    if (collectionId !== "" && id !== "") {
      const db = getFirestore();
      const colRef = collection(db, DBTableStamps);
      const q = query(colRef, where("_id", "==", id));
      const querySnapshot = await getDocs(q);
      const batch = writeBatch(db);
      querySnapshot.forEach((doc) => {
        const docRef: DocumentReference = doc.ref;

        batch.update(docRef, {
          collectionId: collectionId,
        });
      });

      console.log("COLLECTION_ID changed", id);
      await batch.commit();
    }
  };

  /** update stamp in table table, change extension of imgLocal, replace .jpg with .png */
  private _onChangeExtension = async (id: string, ext: string) => {
    const stampToUpdate = this.props.logic
      .playground()
      .stamps.find((d) => d._id === id);

    if (stampToUpdate) {
      const db = getFirestore();
      const colRef = collection(db, DBTableStamps);

      const q = query(colRef, where("_id", "==", id));
      const querySnapshot = await getDocs(q);
      const batch = writeBatch(db);
      querySnapshot.forEach((doc) => {
        const docRef: DocumentReference = doc.ref;

        batch.update(docRef, {
          imgLocal: (stampToUpdate.imgLocal ?? "").replace(".jpg", "." + ext),
        });
      });

      await batch.commit();
    } else {
      console.log("not found", id);
    }
  };

  /**
   * If width and height is not set, it will change width to height and height to width (swap)
   *
   */
  private _onChangeWidthHeight = async (
    id?: string,
    width?: number,
    height?: number
  ) => {
    if (id !== undefined) {
      const stampToUpdate = this.props.logic
        .playground()
        .stamps.find((d) => d._id === id);

      if (stampToUpdate) {
        const db = getFirestore();
        const colRef = collection(db, DBTableStamps);

        const q = query(colRef, where("_id", "==", id));
        const querySnapshot = await getDocs(q);
        const batch = writeBatch(db);
        querySnapshot.forEach((doc) => {
          const docRef: DocumentReference = doc.ref;

          batch.update(docRef, {
            height: height ?? stampToUpdate.width,
            width: width ?? stampToUpdate.height,
          });
        });

        console.log("changed", id);
        await batch.commit();
      } else {
        console.log("not found", id);
      }
    }
  };

  private _onStampSneakDetailButtonClick = (stampId?: string) => {
    this.props.logic.playground().toggleDetailedStamp(stampId);
  };

  private _onButtonBackClick = () => {
    this.props.logic.playground().toggleDetailedStamp();
  };
}

export default withLogicContext(observer(PlaygroundModule));
