import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import i18nInit from "./i18n";
import { Logic } from "./logic";
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDV1JIVLF9LAc3-MllfLuCoBuyhhTJ5nEE",
  authDomain: "stampikz.firebaseapp.com",
  projectId: "stampikz",
  storageBucket: "stampikz.appspot.com",
  messagingSenderId: "528633920486",
  appId: "1:528633920486:web:96cc402eac537dd51e7427",
  measurementId: "G-42CTJTYK0R",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
const provider = new GoogleAuthProvider();
export const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then((result) => {
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      const name = result.user?.displayName;
      const photoUrl = result.user?.photoURL;

      localStorage.setItem("name", name ?? "");
      localStorage.setItem("photoUrl", photoUrl ?? "");
      logic.navBar().login();
    })
    .catch((error) => {
      console.log(error);
    });
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const logic = new Logic();
// const i18n = i18nInit("en");

(window as any).app = {
  version: "0.0.1",
  // i18n,
  logic,
};

root.render(
  //   <React.StrictMode>
  <App logic={logic} />
  //   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
