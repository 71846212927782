import { Box } from "@mui/material";
import { confDefault } from "../../conf";
import { Stamp } from "../../types/stamps";
import BoxClose from "../BoxClose";
import StampPreview from "../StampPreview";

interface Props {
  stampsDetailed: Stamp[];
  onStampDetailCloseClick: () => void;
  tag: string;
}

function StampSidePanel(props: Props) {
  const geometa = props.stampsDetailed[0]?.geometa?.find(
    (geo) => geo.type === props.tag
  );

  return (
    <>
      {props.stampsDetailed.length > 0 && (
        <Box
          className="stamp-side-panel"
          onClick={props.onStampDetailCloseClick}
        >
          <Box className="stamp-side-panel-header">
            <Box className="stamp-side-panel-header-title">
              {geometa &&
                parseFloat(String(geometa.location?._lat)).toFixed(5) +
                  ", " +
                  parseFloat(String(geometa.location?._long)).toFixed(5)}{" "}
              [{props.stampsDetailed.length}]
            </Box>
            <Box>
              <BoxClose
                bgColor={confDefault.playground.stampDetail.bgColor}
                onCloseClick={props.onStampDetailCloseClick}
                bottomClose={false}
              />
            </Box>
          </Box>
          {props.stampsDetailed.map((stamp) => (
            <StampPreview key={stamp._id} stamp={stamp} />
          ))}
        </Box>
      )}
    </>
  );
}

export default StampSidePanel;
