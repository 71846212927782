import React, { ReactNode } from "react";
import { Box } from "@mui/material";

interface WrapperProps {
  width: number;
  children: ReactNode;
}

const CenterBoxWrapper: React.FC<WrapperProps> = ({ children, width }) => {
  console.log("WIFH", width);
  return (
    <Box
      className="third-row-wrapper"
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        className="third-row"
        sx={{
          width: width,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default CenterBoxWrapper;
