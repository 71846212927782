import { Box, Grid } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  label?: string;
  value?: string | number | ReactNode;
  size?: "small" | "medium" | "large";
  html?: boolean;
  wrap?: boolean;
  isNumber?: boolean;
  source?: string;
}

const catalogLabels: { [key: string]: string } = {
  Mi: "Michel",
  Yt: "Yvert & Tellier",
  Sg: "Stanley Gibbons",
  AFA: "AFA Forlag",
  Pol: "Katalog Polskich Znaków Pocztowych",
  WAD: "World Association for the Development of Philately (WADP)",
  Nor: "Norgeskatalogen",
  NVPH: "Nederlandse Vereniging van Postzegelhandelaren",
  Sn: "Serial Number - Scott",
  Facit: "Facit",
  Un: "Unificato",
  POF: "POFIS",
  Bel: "Catalogue Officiel de Timbres-Poste de Belgique",
  PHu: "Philatelia Hungarica",
  Col: "Colnect codes",
  Sas: "Sassone",
  Bol: "Bolaffi",
  Zum: "Zumstein",
  Edi: "Edifil",
  RHM: "RHM Filatelistas (Brazil)",
};

const restrictedCatalogs: string[] = ["Col"];

function AttributeCatalogs(props: Props) {
  if (props.isNumber && typeof props.value !== "number") return <></>;
  if (props.value === undefined || props.value === null || props.value === "")
    return <></>;

  let valueArray: string[] = [];
  if (typeof props.value === "string") {
    valueArray = props.value.split(",");
  }

  valueArray = valueArray
    .filter((value) => !value.trim().startsWith("Sn:"))
    .map((value) => value.trim())
    .sort();

  // remove restricted catalogs
  valueArray = valueArray.filter((value) => {
    const code = value.split(":")[0].trim();
    return !restrictedCatalogs.includes(code);
  });

  return valueArray.length > 0 ? (
    <>
      {/* {sn && (
        <Grid
          className="attribute"
          container
          wrap={props.wrap ? "wrap" : "nowrap"}
        >
          {props.label && (
            <Grid item>
              <Box className="attribute-label">Stamp number: </Box>
            </Grid>
          )}
          <Grid item>
            <Box className="attribute-value">{snValue}</Box>
          </Grid>
        </Grid>
      )} */}

      <Grid
        className="attribute"
        container
        wrap={props.wrap ? "wrap" : "nowrap"}
      >
        {props.label && (
          <Grid item>
            <Box className="attribute-label">{props.label}:</Box>
          </Grid>
        )}
        <Grid item>
          {valueArray.map((value: string, index: number) => {
            const code = value.split(":")[0].trim();
            const label = catalogLabels[code] || code;
            const number = value.split(":")[1].trim();

            if (number === "Unlisted") return <></>;

            return (
              <Box key={index} className="attribute-value">
                {label}: {value.split(":")[1]}
              </Box>
            );
          })}
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
}

export default AttributeCatalogs;
