import React from "react";
import { Box, Button, Grid, Chip, Typography } from "@mui/material";

import Attribute from "../Attribute";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { WithLogic, withLogicContext } from "../../App";
import { stampWidth } from "../../configs";
import {
  generateStampSeoTitle,
  getCountryNameFromCode,
  getImageSrc,
  isDevelopersMode,
  prepareUrl,
} from "../../utils/string-utils";
import { useNavigate } from "react-router-dom";
import StampImage from "../StampImage";
import StampSneak from "../StampSneak";
import { Helmet } from "react-helmet-async";
import { Stamp } from "../../types/stamps";
import { toJS } from "mobx";
import AttributeCatalogs from "../AttributeCatalogs";
import AttributeGeometa from "../AttributeGeoMeta";

interface Props extends WithLogic {
  columns: number;
  stamp?: Stamp;
  onButtonBackClick?: () => void;
  onChipClick?: (tag_id: string) => void;
  onStampSneakDevAddCollectionId?: (collectionId: string, _id: string) => void;
  onStampSneakDevAddOmnibusId?: (omnibusId: string, _id: string) => void;
  onCollectionImageClick?: (id: string) => void;
  onOmnibusImageClick?: (id: string) => void;
  onLoadTagsClick?: (id?: string) => void;
  onDevAddThemeTag?: (tag_id: string) => void;
  onDevAddLabel?: (label: string, id: string) => void;
  onDevChangeExtension?: (_id: string, ext: string) => void;
  onDevGeolocation?: (_id: string, geoLatLng: string, geoTag: string) => void;
  onDevBiometa?: (_id: string, bioName: string, bioTag: string) => void;
  onDevChangeWidthHeight?: (
    _id: string,
    width?: number,
    height?: number
  ) => void;
}

function StampDetail(props: Props) {
  const navigate = useNavigate();
  const pageTitle = generateStampSeoTitle(props.stamp);

  if (props.logic.playground().stampsPreviewed === undefined) {
    return <></>;
  }

  function onButtonBackClick() {
    if (props.logic.playground().tag) {
      navigate(`/theme/${props.logic.playground().tag?.replaceAll("_", "-")}`);
    } else {
      navigate("/");
    }
    props.onButtonBackClick?.();
  }

  function onChipClick(label: string) {
    navigate(`/theme/${label}`);
    if (props.onChipClick) {
      props.onChipClick(label);
    }
  }

  function onStampCollectionClick(stamp: Stamp) {
    navigate(`/stamp/${stamp.id}`);
    props.onCollectionImageClick?.(stamp.id);
  }

  function onStampOmnibusClick(stamp: Stamp) {
    navigate(`/stamp/${stamp.id}`);
    props.onOmnibusImageClick?.(stamp.id);
  }

  const imgBg = "#fff";
  const textBg = "#fff";
  const myTags = toJS(props.logic.playground().tags);

  console.log("aaAAAAaa", toJS(props.stamp));

  const tags = props.stamp?.themeTags ?? [];
  const metas = props.stamp?.biometa?.map((d) => d.name) ?? [];
  console.log("metasx", metas);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        {props.stamp && (
          <meta
            name="description"
            content={
              "Stamp " +
              (props.stamp?.nameEn ?? props.stamp?.name) +
              " from " +
              getCountryNameFromCode(props.stamp.country) +
              props.stamp.labels
                ? ": " + props.stamp.labels?.join(", ") + "."
                : "."
            }
          />
        )}
      </Helmet>
      <Box
        className="stamp-detail"
        sx={{
          display: "flex",
          justifyContent: "center",
          background: `linear-gradient(to right, ${imgBg} 50%, ${textBg} 50%)`,
        }}
      >
        <Box
          sx={{
            width: props.columns * stampWidth + props.columns * 32,
          }}
        >
          <Box>
            <Grid container className="stamp-detail-container">
              <Grid
                item
                sm={12}
                md={5}
                className={`stamp-detail-image-half stamp-detail-image-half-${props.stamp?.country}`}
                sx={{ backgroundColor: imgBg }}
                padding={0}
              >
                <Box className="stamp-detail-box">
                  <img
                    className="stamp-detail-image"
                    src={getImageSrc(
                      false,
                      props.stamp?.imgLocal,
                      props.stamp?.imgsLocalList,
                      props.stamp?.countryNameCode ?? ""
                    )}
                    style={{
                      minWidth:
                        (props.stamp?.imgWidth ?? 1) >
                        (props.stamp?.imgHeight ?? 1)
                          ? 376
                          : undefined,
                      minHeight:
                        (props.stamp?.imgWidth ?? 1) <
                        (props.stamp?.imgHeight ?? 1)
                          ? 376
                          : undefined,
                      width: "100%",
                    }}
                    alt="stamp"
                  />
                  <br />
                  {isDevelopersMode() && props.stamp && (
                    <StampSneak
                      isLandingPage={false}
                      stamp={props.stamp}
                      // onDevAddThemeTag={this._addThemeTag}
                      onDevAddCollectionId={
                        props.onStampSneakDevAddCollectionId
                      }
                      onDevAddOmnibusId={props.onStampSneakDevAddOmnibusId}
                      onLoadTagsClick={props.onLoadTagsClick}
                      onDevAddThemeTag={props.onDevAddThemeTag}
                      onDevAddLabel={props.onDevAddLabel}
                      onDevChangeExtension={props.onDevChangeExtension}
                      onDevChangeWidthHeight={props.onDevChangeWidthHeight}
                      onDevGeolocation={props.onDevGeolocation}
                      onDevBiometa={props.onDevBiometa}
                    />
                  )}
                </Box>
              </Grid>
              <Grid
                item
                sm={12}
                md={7}
                className="stamp-detail-info"
                sx={{
                  backgroundColor: textBg,
                  borderLeftColor: textBg,
                }}
              >
                <Box>
                  <Grid container spacing={1}>
                    {props.logic.navBar().logged && (
                      <>
                        <Grid item>
                          <Button variant="outlined" onClick={() => {}}>
                            <FavoriteIcon />
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant="outlined" onClick={() => {}}>
                            <AddCircle />
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant="outlined" onClick={() => {}}>
                            <RemoveCircle />
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
                <Box className="stamp-detail-info-head">
                  <Grid container spacing={3} wrap="nowrap">
                    {props.logic.playground().stamps.length > 1 && (
                      <Grid item>
                        <Box className="stamp-detail-back-button">
                          <Button
                            variant="outlined"
                            size="medium"
                            onClick={onButtonBackClick}
                          >
                            <ArrowBackIcon />
                          </Button>
                        </Box>
                      </Grid>
                    )}
                    <Grid item>
                      {(props.stamp?.nameEn || props.stamp?.name) && (
                        <Typography className="stamp-detail-name">
                          {props.stamp?.nameEn ?? props.stamp?.name}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
                {tags && (
                  <Attribute
                    label="Themes"
                    value={tags
                      .map((tag, i) => (
                        <a
                          href={prepareUrl(tag)}
                          key={i}
                          onClick={(e) => {
                            onChipClick(`${tag}`);
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <Chip
                            className="collection-words-chip"
                            key={tag}
                            label={myTags.find((t) => t.tag_id === tag)?.label}
                            sx={{
                              borderRadius: "4px",
                            }}
                          />
                        </a>
                      ))
                      .concat(
                        metas.map((meta, i) => (
                          <Chip
                            className="collection-words-chip"
                            color="warning"
                            key={meta}
                            label={meta}
                            sx={{
                              borderRadius: "4px",
                            }}
                          />
                        ))
                      )}
                  />
                )}
                {/* {props.stamp?._themeTags &&
                props.stamp._themeTags.length > 0 ? (
                  <Attribute
                    label="Themes"
                    value={props.stamp?._themeTags.map((tag, i) => (
                      <a
                        href={prepareUrl(tag.tag_id)}
                        key={i}
                        onClick={(e) => {
                          onChipClick(`${tag.tag_id}`);
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <Chip
                          className="collection-words-chip"
                          key={tag.tag_id}
                          label={tag.label}
                          sx={{
                            borderRadius: "4px",
                          }}
                        />
                      </a>
                    ))}
                  />
                ) : (
                  props.logic.playground().stampDetailedTagsLoading && (
                    <Attribute
                      label="Themes"
                      value={
                        <Chip
                          className="collection-words-chip"
                          label={"              "}
                          sx={{
                            borderRadius: "4px",
                            width: "130px",
                          }}
                        />
                      }
                    />
                  )
                )} */}
                {props.stamp?.labels && props.stamp.labels.length > 0 && (
                  <Attribute
                    label="Texts on stamp"
                    value={props.stamp.labels.map((label, i) => (
                      <React.Fragment key={i}>
                        {i !== 0 && (
                          <Box component="span" className="unselectable">
                            •
                          </Box>
                        )}
                        <Box component="span">{label}</Box>
                      </React.Fragment>
                    ))}
                  />
                )}
                {props.stamp?.country && (
                  <Attribute
                    label="Country"
                    value={getCountryNameFromCode(props.stamp?.country)}
                  />
                )}
                {props.stamp?.width !== undefined &&
                  props.stamp?.height !== undefined &&
                  props.stamp?.width > 2 &&
                  props.stamp?.height > 2 && (
                    <Attribute
                      label="Size"
                      value={
                        <>
                          {props.stamp?.width} x {props.stamp?.height} mm
                        </>
                      }
                    />
                  )}
                {isDevelopersMode() &&
                  props.stamp?.imgWidth &&
                  props.stamp?.imgWidth && (
                    <Attribute
                      label="Img size [px]"
                      value={
                        <>
                          {props.stamp?.imgWidth} x {props.stamp?.imgHeight} px
                        </>
                      }
                    />
                  )}
                {props.stamp?.printTechniqueEn && (
                  <Attribute
                    label="Print technique"
                    value={props.stamp?.printTechniqueEn}
                  />
                )}
                {props.stamp?.print && (
                  <Attribute label="Printer" value={props.stamp?.print} />
                )}
                {props.stamp?.countryName && (
                  <Attribute label="Country" value={props.stamp?.countryName} />
                )}
                {props.stamp?.emission && (
                  <Attribute
                    isNumber={true}
                    label="Emission"
                    value={props.stamp?.emission}
                  />
                )}
                {props.stamp?.perforation && (
                  <Attribute
                    label="Perforation"
                    value={props.stamp?.perforation}
                  />
                )}
                {props.stamp?.paper && (
                  <Attribute label="Paper" value={props.stamp?.paper} />
                )}
                {props.stamp?.date && (
                  <Attribute label="Date of issue" value={props.stamp?.date} />
                )}
                {props.stamp?.expiryDate && (
                  <Attribute
                    label="Date of expiration"
                    value={props.stamp?.expiryDate}
                  />
                )}
                {props.stamp?.dateWithdrawn && (
                  <Attribute
                    label="Date of withdrawn"
                    value={props.stamp?.dateWithdrawn}
                  />
                )}
                {props.stamp?.nominal && (
                  <Attribute
                    label="Nominal"
                    value={props.stamp?.nominal.replace(
                      " º - No Face Value",
                      ""
                    )}
                  />
                )}
                {props.stamp?.author && (
                  <Attribute label="Author" value={props.stamp?.author} />
                )}
                {props.stamp?.designer && (
                  <Attribute label="Designer" value={props.stamp?.designer} />
                )}
                {props.stamp?.illustrator && (
                  <Attribute
                    label="Illustrator"
                    value={props.stamp?.illustrator}
                  />
                )}
                {props.stamp?.engraver && (
                  <Attribute label="Engraver" value={props.stamp?.engraver} />
                )}
                {(props.stamp?.bondingEn ?? props.stamp?.bonding) && (
                  <Attribute
                    label="Bonding"
                    value={props.stamp?.bondingEn ?? props.stamp?.bonding}
                  />
                )}
                {props.stamp?.color && (
                  <Attribute label="Color" value={props.stamp?.color} />
                )}
                {props.stamp?.gum && (
                  <Attribute label="Gum" value={props.stamp?.gum} />
                )}
                {props.stamp?.issuedIn && (
                  <Attribute label="Issued in" value={props.stamp?.issuedIn} />
                )}
                {props.stamp?.wns && (
                  <Attribute label="WNS" value={props.stamp?.wns} />
                )}
                {props.stamp?.series && (
                  <Attribute label="Collection" value={props.stamp?.series} />
                )}
                {props.stamp?.collectionTags && (
                  <Attribute
                    label="Series"
                    value={props.stamp?.collectionTags?.join(", ")}
                  />
                )}
                {props.stamp?.format && (
                  <Attribute label="Format" value={props.stamp?.format} />
                )}
                {props.stamp?.geometa && props.stamp?.geometa.length > 0 && (
                  <AttributeGeometa
                    label="Geometa"
                    value={props.stamp?.geometa}
                  />
                )}
                {props.stamp?.catalogs && props.stamp?.catalogs.length > 0 && (
                  <AttributeCatalogs
                    label="Catalogs"
                    value={props.stamp?.catalogs}
                  />
                )}
                {props.stamp?.awardsEn && (
                  <Attribute label="Note" value={props.stamp?.awardsEn} />
                )}
                {props.stamp?.description && (
                  <Attribute
                    label="Description"
                    wrap={true}
                    value={props.stamp?.description}
                    html={true}
                  />
                )}
                {props.stamp?.themeDescription && (
                  <Attribute
                    label="Description"
                    wrap={true}
                    value={props.stamp?.themeDescription}
                    html={true}
                  />
                )}
                {isDevelopersMode() && (
                  <>
                    {props.stamp?.img && (
                      <Attribute label="IMG URL" value={props.stamp?.img} />
                    )}
                    {props.stamp?.collectionId && (
                      <Attribute
                        label="COLLECTION ID"
                        value={props.stamp?.collectionId}
                      />
                    )}
                    {props.stamp?.url && (
                      <Attribute label="URL" value={props.stamp?.url} />
                    )}
                    {props.stamp?.urlEn && (
                      <Attribute label="URL en" value={props.stamp?.urlEn} />
                    )}
                    {props.stamp?.id && (
                      <Attribute label="ID" value={props.stamp?.id} />
                    )}
                    {props.stamp?._id && (
                      <Attribute label="_ID" value={props.stamp?._id} />
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: props.columns * stampWidth + props.columns * 32,
            }}
          >
            {props.stamp?._withinCollection &&
              props.stamp._withinCollection?.filter(
                (stamp) =>
                  stamp.format?.length === 5 &&
                  stamp.format?.substring(0, 5) === "Stamp" &&
                  stamp._disabledImg !== true
              ).length > 1 && (
                <Grid container className="stamp-detail-collection">
                  <Grid item xs={12}>
                    <Typography className="stamp-detail-heading2">
                      Collection:{" "}
                      {props.logic.playground().collectionPreviewed?.label ??
                        ""}
                    </Typography>
                  </Grid>
                  <Grid container alignItems="baseline">
                    {props.stamp?._withinCollection
                      ?.filter(
                        (stamp) =>
                          stamp.format?.length === 5 &&
                          stamp.format?.substring(0, 5) === "Stamp" &&
                          stamp._disabledImg !== true
                      )
                      .map((stamp, i) => (
                        <Grid item key={i}>
                          <StampImage
                            stamp={stamp}
                            onImageClick={() => onStampCollectionClick(stamp)}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              )}

            {props.stamp?._withinOmnibus &&
              props.stamp._withinOmnibus?.filter(
                (stamp) =>
                  stamp.format?.length === 5 &&
                  stamp.format?.substring(0, 5) === "Stamp" &&
                  stamp._disabledImg !== true
              ).length > 1 && (
                <Grid container className="stamp-detail-collection">
                  <Grid item xs={12}>
                    <Typography className="stamp-detail-heading2">
                      Omnibus:{" "}
                    </Typography>
                  </Grid>
                  <Grid container alignItems="baseline">
                    {props.stamp?._withinOmnibus
                      ?.filter(
                        (stamp) =>
                          stamp.format?.length === 5 &&
                          stamp.format?.substring(0, 5) === "Stamp" &&
                          stamp._disabledImg !== true
                      )
                      .map((stamp, i) => (
                        <Grid item key={i}>
                          <StampImage
                            stamp={stamp}
                            onImageClick={() => onStampOmnibusClick(stamp)}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              )}

            {props.stamp?._withinCollection?.filter(
              (stamp) => stamp.format?.substring(0, 9) === "Full Pane"
            ) &&
              props.stamp?._withinCollection?.filter(
                (stamp) =>
                  stamp.format?.substring(0, 9) === "Full Pane" &&
                  stamp.imgLocal !== ""
              ).length > 0 && (
                <Grid
                  container
                  className="stamp-detail-collection stamp-detail-full-pane"
                >
                  <Grid item xs={12}>
                    <Typography className="stamp-detail-heading2">
                      Full Pane:
                    </Typography>
                  </Grid>
                  <Grid container alignItems="baseline">
                    {props.stamp?._withinCollection
                      ?.filter(
                        (stamp) =>
                          stamp.format?.substring(0, 9) === "Full Pane" &&
                          stamp.imgLocal !== ""
                      )
                      .map((stamp, i) => (
                        <Grid item key={i}>
                          <StampImage
                            stamp={stamp}
                            size="large"
                            //   onImageClick={() => onStampCollectionClick(stamp)}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              )}

            {props.stamp?._withinCollection?.filter(
              (stamp) => stamp.format?.substring(0, 14) === "Souvenir Sheet"
            ) &&
              props.stamp?._withinCollection?.filter(
                (stamp) =>
                  stamp.format?.substring(0, 14) === "Souvenir Sheet" &&
                  stamp.imgLocal !== ""
              ).length > 0 && (
                <Grid
                  container
                  className="stamp-detail-collection stamp-detail-souvenir-sheet"
                >
                  <Grid item xs={12}>
                    <Typography className="stamp-detail-heading2">
                      Souvernir Sheet:{" "}
                    </Typography>
                  </Grid>
                  <Grid container alignItems="baseline">
                    {props.stamp?._withinCollection
                      ?.filter(
                        (stamp) =>
                          stamp.format?.substring(0, 14) === "Souvenir Sheet" &&
                          stamp.imgLocal !== ""
                      )
                      .map((stamp, i) => (
                        <Grid item key={i}>
                          <StampImage stamp={stamp} size="large" />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              )}

            {props.stamp?._withinCollection?.filter(
              (stamp) => stamp.format?.substring(0, 10) === "Mini Sheet"
            ) &&
              props.stamp?._withinCollection?.filter(
                (stamp) => stamp.format?.substring(0, 10) === "Mini Sheet"
              ).length > 0 && (
                <Grid
                  container
                  className="stamp-detail-collection stamp-detail-mini-sheet"
                >
                  <Grid item xs={12}>
                    <Typography className="stamp-detail-heading2">
                      Mini Sheet:{" "}
                    </Typography>
                  </Grid>
                  <Grid container alignItems="baseline">
                    {props.stamp?._withinCollection
                      ?.filter(
                        (stamp) =>
                          stamp.format?.substring(0, 14) === "Mini Sheet"
                      )
                      .map((stamp, i) => (
                        <Grid item key={i}>
                          <StampImage
                            stamp={stamp}
                            size="large"
                            onImageClick={() => onStampCollectionClick(stamp)}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              )}
            {isDevelopersMode() && (
              <>
                <hr />
                <Box>
                  {props.logic.playground().collectionPreviewed?.id}:
                  {props.logic.playground().collectionPreviewed?.label}
                </Box>
                <Grid container className="stamp-detail-collection">
                  <Grid container alignItems="baseline">
                    {props.stamp?._withinCollection?.map((stamp, i) => (
                      <Grid item key={i}>
                        <StampSneak
                          isLandingPage={false}
                          stamp={stamp}
                          // onDevAddThemeTag={this._addThemeTag}
                          onDevAddCollectionId={
                            props.onStampSneakDevAddCollectionId
                          }
                          onDevAddOmnibusId={props.onStampSneakDevAddOmnibusId}
                          onLoadTagsClick={props.onLoadTagsClick}
                          onDevAddThemeTag={props.onDevAddThemeTag}
                          onDevAddLabel={props.onDevAddLabel}
                          onDevChangeExtension={props.onDevChangeExtension}
                          onDevChangeWidthHeight={props.onDevChangeWidthHeight}
                          onDevBiometa={props.onDevBiometa}
                          onDevGeolocation={props.onDevGeolocation}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Box>
      }
      {/* 
      {props.stamp?.themeDescription && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
          className="collection-after"
        >
          <Box
            sx={{
              width: props.columns * stampWidth + props.columns * 32,
            }}
          >
            {props.stamp?.themeDescription && (
              <Attribute
                // label="Description"
                wrap={false}
                value={props.stamp?.themeDescription}
                html={true}
                // source={props.stamp?.url ?? props.stamp?.urlEn}
                size="large"
              />
            )}
          </Box>
        </Box>
      )}
               */}
    </>
  );
}

export default withLogicContext(StampDetail);
