import { developersMode, seoTitleSuffix, themeText } from "../configs";
import { Stamp } from "../types/stamps";

export function removeQueryParams(imgUrl: string) {
  return imgUrl.split("?")[0];
}

export function prepareUrl(tag: string) {
  return tag ? `/${themeText}/${tag.replaceAll("_", "-")}` : "#";
}

export function generateStampSeoTitle(stamp?: Stamp) {
  if (stamp) {
    const stampTitleName = stamp?.nameEn ?? stamp?.name ?? "";
    const stampTitleCountry = getCountryNameFromCode(stamp.country) ?? "";
    return stampTitleName + " " + stampTitleCountry + seoTitleSuffix;
  }
  return "";
}

export function generateThemeSeoTitle(theme: string) {
  return theme + seoTitleSuffix;
}

export function getFlagColorClass(countryCode: string) {
  switch (countryCode.toUpperCase()) {
    case "CA":
      return "#e1e1e1";
    case "FI":
      return "#ffffff";
    // case "CS":
    //   return "#000000";
    case "TG":
      return "#000000";
    case "AX":
      return "#ebebeb";
    default:
      return "#ffffff";
  }
}

export function getImageSrc(
  withResized: boolean,
  imgLocal: string = "",
  imgsLocalList: string[] = [],
  country: string
) {
  let newImgLocal = "";

  const imgConfig = {
    hungary: 1,
    slovakia: 1,
  };
  const stampCountryKey = "/stamps/undefined/";

  const imgToView =
    imgConfig[country as keyof typeof imgConfig] && imgsLocalList.length > 0
      ? imgsLocalList[imgConfig[country as keyof typeof imgConfig]]
      : imgLocal;

  if (imgToView && imgToView?.split("/").length > 4) {
    newImgLocal = imgToView.replace(stampCountryKey, "");
  } else if (imgToView) {
    newImgLocal = imgToView;
  }

  if (newImgLocal[0] !== "/") {
    newImgLocal = "/" + newImgLocal;
  }

  const parts = newImgLocal.split("/");

  if (withResized) {
    parts.splice(parts.length - 1, 0, "resized");
  } else if (!parts.includes("sk")) {
    parts.splice(parts.length - 1, 0, "resized750");
  }

  const newImgLocal2 = parts.join("/");

  return newImgLocal2;
}

export function isDevelopersMode() {
  const url = window.location.href;
  if (url.includes("dev=1")) {
    return true;
  }
  return developersMode;
}

export function getFileNameFromUrl(url: string) {
  return url.split("/").pop();
}

export function getImgSrcDetail(url: string) {
  if (url.includes("/fi/")) {
    url = url.replace("/fi/", "/fi/lg/");
  }

  if (url.includes("/sk/")) {
    url = url.replace("/sk/", "/sk/lg/");
    url = url.replace("_medium", "_large");
  }

  if (url[0] !== "/") {
    url = "/" + url;
  }
  return url;
}

export function getCountryNameFromCode(code?: string) {
  if (!code) {
    return "";
  }
  switch (code.toUpperCase()) {
    case "AE-AJ":
      return "Ajman";
    case "AE-SH":
      return "Sharjah";
    case "ES":
      return "Spain";
    case "SU":
      return "Soviet Union";
    case "MT":
      return "Malta";
    case "LC":
      return "Saint Lucia";
    case "NE":
      return "Niger";

    case "CZ":
      return "Czech Republic";
    case "SK":
      return "Slovakia";
    case "HU":
      return "Hungary";
    case "PL":
      return "Poland";
    case "IT":
      return "Italy";
    case "SL":
      // case: "SI":
      return "Slovenia";
    case "TG":
      return "Togo";
    case "NL":
      return "Nederland";
    case "SG":
      return "Singapore";
    case "IN":
      return "India";
    case "SM":
      return "San Marino";
    case "SE":
      return "Sweden";
    case "NO":
      return "Norway";
    case "CS":
      return "Czechoslovakia";
    case "VN":
      return "Vietnam";
    case "MC":
      return "Monaco";
    case "NZ":
      return "New Zealand";
    case "AF":
      return "Afghanistan";
    case "AX":
      return "Åland Islands";
    case "AL":
      return "Albania";
    case "DZ":
      return "Algeria";
    case "AS":
      return "American Samoa";
    case "AD":
      return "Andorra";
    case "AO":
      return "Angola";
    case "AI":
      return "Anguilla";
    case "AQ":
      return "Antarctica";
    case "AG":
      return "Antigua and Barbuda";
    case "AR":
      return "Argentina";
    case "AM":
      return "Armenia";
    case "AW":
      return "Aruba";
    case "AU":
      return "Australia";
    case "AT":
      return "Austria";
    case "AZ":
      return "Azerbaijan";
    case "BS":
      return "Bahamas";
    case "BH":
      return "Bahrain";
    case "BD":
      return "Bangladesh";
    case "BB":
      return "Barbados";
    case "BY":
      return "Belarus";
    case "BE":
      return "Belgium";
    case "BZ":
      return "Belize";
    case "BJ":
      return "Benin";
    case "BM":
      return "Bermuda";
    case "BT":
      return "Bhutan";
    case "BO":
      return "Bolivia, Plurinational State of";
    case "BQ":
      return "Bonaire, Sint Eustatius and Saba";
    case "BA":
      return "Bosnia and Herzegovina";
    case "BW":
      return "Botswana";
    case "BV":
      return "Bouvet Island";
    case "BR":
      return "Brazil";
    case "IO":
      return "British Indian Ocean Territory";
    case "BN":
      return "Brunei Darussalam";
    case "BG":
      return "Bulgaria";
    case "BF":
      return "Burkina Faso";
    case "BI":
      return "Burundi";
    case "KH":
      return "Cambodia";
    case "CM":
      return "Cameroon";
    case "CA":
      return "Canada";
    case "CV":
      return "Cape Verde";
    case "KY":
      return "Cayman Islands";
    case "CF":
      return "Central African Republic";
    case "TD":
      return "Chad";
    case "CL":
      return "Chile";
    case "CN":
      return "China";
    case "CX":
      return "Christmas Island";
    case "CC":
      return "Cocos (Keeling) Islands";
    case "CO":
      return "Colombia";
    case "KM":
      return "Comoros";
    case "CG":
      return "Congo";
    case "CD":
      return "Congo, the Democratic Republic of the";
    case "CK":
      return "Cook Islands";
    case "CR":
      return "Costa Rica";
    case "CI":
      return "Côte d'Ivoire";
    case "HR":
      return "Croatia";
    case "CU":
      return "Cuba";
    case "CW":
      return "Curaçao";
    case "CY":
      return "Cyprus";
    case "DK":
      return "Denmark";
    case "DJ":
      return "Djibouti";
    case "DM":
      return "Dominica";
    case "DO":
      return "Dominican Republic";
    case "EC":
      return "Ecuador";
    case "EG":
      return "Egypt";
    case "SV":
      return "El Salvador";
    case "GQ":
      return "Equatorial Guinea";
    case "ER":
      return "Eritrea";
    // case "EE":
    case "ET":
      return "Estonia";
    // case "ET":
    //   return "Ethiopia";
    case "FK":
      return "Falkland Islands (Malvinas)";
    case "FO":
      return "Faroe Islands";
    case "FJ":
      return "Fiji";
    case "FI":
      return "Finland";
    case "FR":
      return "France";
    case "GF":
      return "French Guiana";
    case "PF":
      return "French Polynesia";
    case "TF":
      return "French Southern Territories";
    case "GA":
      return "Gabon";
    case "GM":
      return "Gambia";
    case "GE":
      return "Georgia";
    case "DE":
      return "Germany";
    case "GH":
      return "Ghana";
    case "GI":
      return "Gibraltar";
    case "GR":
      return "Greece";
    case "GL":
      return "Greenland";
    case "GD":
      return "Grenada";
    case "GP":
      return "Guadeloupe";
    case "GU":
      return "Guam";
    case "GT":
      return "Guatemala";
    case "GG":
      return "Guernsey";
    case "GN":
      return "Guinea";
    case "GW":
      return "Guinea-Bissau";
    case "GY":
      return "Guyana";
    case "HT":
      return "Haiti";
    case "HM":
      return "Heard Island and McDonald Islands";
    case "VA":
      return "Holy See (Vatican City State)";
    case "HN":
      return "Honduras";
    case "HK":
      return "Hong Kong";
    case "IS":
      return "Iceland";
    case "ID":
      return "Indonesia";
    case "IR":
      return "Iran, Islamic Republic of";
    case "IQ":
      return "Iraq";
    case "IE":
      return "Ireland";
    case "IM":
      return "Isle of Man";
    case "JP":
      return "Japan";
    case "JE":
      return "Jersey";
    case "JO":
      return "Jordan";
    case "KZ":
      return "Kazakhstan";
    case "KE":
      return "Kenya";
    case "KI":
      return "Kiribati";
    case "KP":
      return "Korea, Democratic People's Republic of";

    case "PY":
      return "Paraguay";

    default:
      return "";
  }
}
