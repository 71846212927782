import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
} from "@mui/material";
import numeral from "numeral";
import { WithLogic, withLogicContext } from "../../App";
import { getImageSrc, isDevelopersMode } from "../../utils/string-utils";
import { Stamp } from "../../types/stamps";
import { previewStampSimple } from "../../configs";

export const ENV_FILESERVER = "/s";

interface Props extends WithLogic {
  stamp: Stamp;
}

function StampPreview(props: Props) {
  let authors = [];

  if (props.stamp.author) {
    authors.push(props.stamp.author);
  }
  if (props.stamp.designer) {
    authors.push(props.stamp.designer);
  }
  if (props.stamp.illustrator) {
    authors.push(props.stamp.illustrator);
  }

  return (
    <div className="stamp-preview" key={props.stamp.id}>
      <Card className="stamp-preview-card">
        <CardHeader
          title={props.stamp.nameEn ?? props.stamp.name}
          className="stamp-preview-card-header"
        />
        <Box className="stamp-preview-scroll-content">
          <CardMedia
            component="img"
            image={getImageSrc(
              false,
              props.stamp?.imgLocal,
              props.stamp?.imgsLocalList,
              props.stamp?.countryNameCode ?? ""
            )}
            alt={props.stamp.nameEn ?? props.stamp.name}
          />
          {!previewStampSimple && (
            <CardContent className="stamp-preview-content">
              <>
                {props.stamp.countryName && (
                  <Grid container justifyContent="space-between">
                    <Grid item>Country</Grid>
                    <Grid item>{props.stamp.countryName}</Grid>
                  </Grid>
                )}

                {props.stamp.width && props.stamp.height && (
                  <Grid container justifyContent="space-between">
                    <Grid item>Size</Grid>
                    <Grid item>
                      {props.stamp.width} x {props.stamp.height} mm
                    </Grid>
                  </Grid>
                )}

                {props.stamp.printTechnique && (
                  <Grid container justifyContent="space-between">
                    <Grid item>Print technique</Grid>
                    <Grid item>{props.stamp.printTechnique}</Grid>
                  </Grid>
                )}

                {props.stamp.emission && (
                  <Grid container justifyContent="space-between">
                    <Grid item>Emmision</Grid>
                    <Grid item>
                      {numeral(props.stamp.emission).format("0,0")}
                    </Grid>
                  </Grid>
                )}

                {authors.length > 0 && (
                  <Grid container justifyContent="space-between">
                    <Grid item>Author</Grid>
                    <Grid item>{authors.join(",")}</Grid>
                  </Grid>
                )}

                {props.stamp.date && (
                  <Grid container justifyContent="space-between">
                    <Grid item>Date of issue</Grid>
                    <Grid item>{props.stamp.date}</Grid>
                  </Grid>
                )}

                {props.stamp.print && (
                  <Grid container justifyContent="space-between">
                    <Grid item>Print</Grid>
                    <Grid item>{props.stamp.print}</Grid>
                  </Grid>
                )}

                {props.stamp.themeTags && (
                  <Grid container justifyContent="space-between">
                    <Grid item>Tags</Grid>
                    <Grid item>{props.stamp.themeTags?.join(", ")}</Grid>
                  </Grid>
                )}

                {props.stamp.collectionTags && (
                  <Grid container justifyContent="space-between">
                    <Grid item>Themes</Grid>
                    <Grid item>{props.stamp.collectionTags?.join(", ")}</Grid>
                  </Grid>
                )}
              </>
            </CardContent>
          )}
          {isDevelopersMode() && (
            <>
              {props.stamp._id && (
                <Grid container justifyContent="space-between">
                  <Grid item>_ID</Grid>
                  <Grid item>{props.stamp._id}</Grid>
                </Grid>
              )}
            </>
          )}
        </Box>
      </Card>
    </div>
  );
}

export default withLogicContext(StampPreview);
