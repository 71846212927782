import { Box } from "@mui/material";
import { collectionStampHeight } from "../../configs";
import { Stamp } from "../../types/stamps";
import { get } from "http";
import { getImageSrc } from "../../utils/string-utils";
interface Props {
  stamp: Stamp;
  size?: "small" | "medium" | "large";
  onImageClick?: (id: string) => void;
}

function StampImage(props: Props) {
  const { stamp } = props;

  const hh = 180;

  const ww = undefined;

  //   const imgSrc = stamp.imgLocal
  //     ? stamp.imgLocal.replace("small", "medium")
  //     : "stamps/" +
  //       stamp.country +
  //       "/" +
  //       stamp.img?.split("/")?.[stamp.img?.split("/").length - 1];

  //   const fixedImgSrc = imgSrc[0] === "/" ? imgSrc : "/" + imgSrc;
  const fixedImgDetailSrc = getImageSrc(
    props.size !== "large",
    stamp.imgLocal,
    stamp.imgsLocalList,
    stamp.countryNameCode ?? ""
  );

  return (
    <>
      <Box className={``}>
        <img
          src={fixedImgDetailSrc}
          alt={"Stamp " + stamp._id}
          loading="lazy"
          width={
            props.size
              ? undefined
              : (stamp.imgWidth ?? 0) >= (stamp.imgHeight ?? 0)
              ? 250
              : undefined
          }
          height={
            props.size
              ? undefined
              : (stamp.imgHeight ?? 0) >= (stamp.imgWidth ?? 0)
              ? 250
              : undefined
          }
          style={{
            display: "block",
            margin: "0 auto",
            cursor: props.onImageClick ? "pointer" : "default",
            maxHeight: props.size === "large" ? "750px" : undefined,
            maxWidth: props.size === "large" ? "750px" : undefined,
          }}
          onClick={() => props.onImageClick?.(stamp.id)}
        />
      </Box>
    </>
  );
}

export default StampImage;
