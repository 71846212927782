import React, { useMemo, useCallback } from "react";
import { WithLogic, withLogicContext } from "../../App";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import menuJson from "../../settings/xmenu.json";
import { generateRandomString } from "../../modules/playground/playground-logic";
import { prepareUrl } from "../../utils/string-utils";
import { TagModel } from "../../types/stamps";
import { toJS } from "mobx";

interface MenuItemModel {
  tag: string;
  label?: string;
  children?: MenuItemModel[];
  active?: boolean;
  empty?: boolean;
}

interface Props extends WithLogic {
  tags: TagModel[];
  onChipClick?: (tag_id: string) => void;
  onRandomClick?: (hash: string) => void;
}

/** Custom hook to manage menu checkbox */
function useMobileMenu() {
  const closeMobileMenu = useCallback(() => {
    const checkbox = document.getElementById("toggle") as HTMLInputElement;
    if (checkbox) checkbox.checked = false;
  }, []);

  return { closeMobileMenu };
}

/** MenuItem Component for rendering individual menu items */
const MenuItem: React.FC<{
  item: MenuItemModel;
  memoTags: TagModel[] | undefined;
  onNavigate: (tag: string) => void;
  closeMobileMenu: () => void;
}> = ({ item, memoTags, onNavigate, closeMobileMenu }) => {
  const itemTag = memoTags?.find((tag) => tag.tag_id === item.tag);

  return (
    <li>
      <a
        href={item.tag ? prepareUrl(item.tag) : "#"}
        onClick={(e) => {
          e.preventDefault();
          if (item.empty !== true) {
            if (!item.active) onNavigate(item.tag);
            closeMobileMenu();
          }
        }}
        className="menu-link menu-bar-link"
        aria-haspopup={!!item.children?.length}
      >
        {item.empty !== true ? "#" : "_"}
        {itemTag?.label.toLowerCase() || item.tag}
      </a>
      {item.children && (
        <ul className="mega-menu mega-menu--multiLevel" role="menu">
          {item.children.map((child, index) => (
            <li key={index}>
              <a
                href={prepareUrl(child.tag)}
                onClick={(e) => {
                  e.preventDefault();
                  onNavigate(child.tag);
                }}
                className="menu-link mega-menu-link"
              >
                #
                {memoTags
                  ?.find((tag) => tag.tag_id === child.tag)
                  ?.label.toLowerCase() || child.tag}
              </a>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

function ThemesMenu(props: Props) {
  const navigate = useNavigate();
  const { closeMobileMenu } = useMobileMenu();
  const menu = menuJson as MenuItemModel[];

  const memoTags = useMemo(() => toJS(props.tags), [props.tags]);

  const handleNavigation = useCallback(
    (tag: string) => {
      navigate(`/theme/${tag.replaceAll("_", "-")}`);
      props.onChipClick?.(tag);
    },
    [navigate, props.onChipClick]
  );

  const handleRandomClick = useCallback(() => {
    navigate("");
    const reloadHash = generateRandomString(2);
    props.onRandomClick?.(reloadHash);
  }, [props.onRandomClick]);

  const renderedMenuItems = useMemo(
    () =>
      menu.map((item, index) => (
        <MenuItem
          key={index}
          item={item}
          memoTags={memoTags}
          onNavigate={handleNavigation}
          closeMobileMenu={closeMobileMenu}
        />
      )),
    [menu, memoTags, handleNavigation, closeMobileMenu]
  );

  return (
    <Box>
      <Box className="head-page-mobile">
        <a href="/">WeAreStamps.net</a>
      </Box>
      <div className="nav">
        <nav>
          <label htmlFor="toggle">
            <Box className="mobile-menu-trigger">
              <Box>
                <Box />
                <Box />
                <Box />
              </Box>
            </Box>
          </label>
          <input type="checkbox" id="toggle" className="visually-hidden" />
          <ul className="menu menu-bar">
            {renderedMenuItems}
            <li className="random-item">
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  handleRandomClick();
                }}
                aria-haspopup="false"
              >
                %random
              </a>
            </li>
            <li className="mobile-menu-header">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  closeMobileMenu();
                }}
                aria-haspopup="false"
              >
                <span className="menu-close-button" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </Box>
  );
}

export default withLogicContext(ThemesMenu);
