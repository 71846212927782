import { Button, Grid, ButtonGroup } from "@mui/material";
import { WithLogic, withLogicContext } from "../../App";
import { observer } from "mobx-react";
import MapIcon from "@mui/icons-material/Map";
import { dbLimitThemes } from "../../configs";

interface Props extends WithLogic {
  onMapToggle?: () => void;
}

function ThemePagination({ logic, onMapToggle }: Props) {
  const playground = logic.playground();
  const { stampsTagsIds, tagPreviewed, pageIndex, list, stampDetailed } =
    playground;
  const paginationLength = Math.ceil(stampsTagsIds.length / dbLimitThemes);

  const handlePageChange = (newIndex: number, e: React.MouseEvent) => {
    playground.setTagPageIndex(newIndex);
    e.stopPropagation();
    e.preventDefault();
  };

  const Label = (
    <Button className="label">{tagPreviewed?.label || "Unknown"}</Button>
  );

  const PreviousButton = (
    <Button
      disabled={pageIndex === 0}
      className={`arrow-index${pageIndex === 0 ? " disabled-index" : ""}`}
      onClick={(e) => handlePageChange(pageIndex - 1, e)}
    >
      &lt;
    </Button>
  );

  const NextButton = (
    <Button
      disabled={pageIndex === paginationLength - 1}
      className={`arrow-index${
        pageIndex === paginationLength - 1 ? " disabled-index" : ""
      }`}
      onClick={(e) => handlePageChange(pageIndex + 1, e)}
    >
      &gt;
    </Button>
  );

  const ActiveButton = () => {
    return Array.from({ length: paginationLength }, (_, index) => (
      <Button
        key={index}
        disabled={index === pageIndex}
        className={index === pageIndex ? "active-index" : "number-index"}
        onClick={(e) => handlePageChange(index, e)}
      >
        {index + 1}
        <span className="menu-length-info">&nbsp;/ {paginationLength}</span>
      </Button>
    ));
  };

  const MapButton = (
    <Button className="map-btn" onClick={onMapToggle}>
      <span>
        <MapIcon />
      </span>
    </Button>
  );

  if (list !== "theme") return null;

  return (
    <Grid container className="theme-heading">
      {paginationLength > 20 ? (
        <Grid item>
          <ButtonGroup variant="contained" className="pagination">
            {Label}
            {onMapToggle && MapButton}
            {PreviousButton}
            <Button disabled className="active-index">
              {pageIndex + 1}&nbsp;
              <span> / {paginationLength}</span>
            </Button>
            {NextButton}
          </ButtonGroup>
        </Grid>
      ) : (
        stampsTagsIds.length > 0 && (
          <Grid item>
            <ButtonGroup variant="contained" className="pagination">
              {Label}
              {onMapToggle && MapButton}
              {PreviousButton}
              {stampDetailed === undefined && ActiveButton()}
              {NextButton}
            </ButtonGroup>
          </Grid>
        )
      )}
    </Grid>
  );
}

export default withLogicContext(observer(ThemePagination));
