import { WithLogic, withLogicContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { TAG_COUNT_MIN } from "../../configs";
import Stack from "@mui/material/Stack";
import menuJson from "../../settings/xmenu.json";
import { generateRandomString } from "../../modules/playground/playground-logic";
import { prepareUrl } from "../../utils/string-utils";
import { TagModel } from "../../types/stamps";

interface MenuItemModel {
  tag: string;
  label?: string;
  childrens?: MenuItemModel[];
  active?: boolean;
}

interface Props extends WithLogic {
  tags?: TagModel[];
  onChipClick?: (tag_id: string) => void;
  onRandomClick?: (hash: string) => void;
}

function ThemesBar(props: Props) {
  const navigate = useNavigate();
  const menu = menuJson as MenuItemModel[];

  function onChipClick(label: string) {
    navigate(`${label}`);
    if (props.onChipClick) {
      props.onChipClick(label);
    }
  }

  function handleRandomClick() {
    const reloadHash = generateRandomString(2);
    console.log("RELOAD CODE", reloadHash);
    navigate(reloadHash);
    props.onRandomClick?.(reloadHash);
  }

  let dbTag = undefined;
  return (
    <nav className="menu">
      <Stack direction="row" spacing={2}>
        <ul>
          {menu.map((item, i) => (
            <li key={i}>
              <a
                href={prepareUrl(item.tag)}
                onClick={(e) => {
                  !item.active && onChipClick(`${item.tag}`);
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                #{item.tag}
              </a>
              <ul>
                {item?.childrens
                  ?.filter((child) => {
                    dbTag = props.tags?.find((d) => d.tag_id === child.tag);
                    return (
                      child.active ||
                      (dbTag?.count && dbTag.count > TAG_COUNT_MIN)
                    );
                  })
                  .map((child, j) => {
                    return (
                      <li key={j}>
                        <a
                          href={prepareUrl(child.tag)}
                          onClick={(e) => {
                            onChipClick(`${child.tag}`);
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                        >
                          #{child.tag}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </li>
          ))}
          <li>
            <a onClick={handleRandomClick}>%random</a>
          </li>
        </ul>
      </Stack>
    </nav>
  );
}

export default withLogicContext(ThemesBar);
