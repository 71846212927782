import { makeAutoObservable, observable, toJS } from "mobx";
import lastStamps from "../../xapi/china_peoples_republic/2022.json";
import menutItems from "../../settings/xmenu.json";

import { Logic } from "../../logic";
import { GeoPoint } from "firebase/firestore";
import {
  DocumentData,
  DocumentReference,
  QuerySnapshot,
  addDoc,
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  startAt,
  deleteDoc,
  where,
  writeBatch,
  doc,
} from "firebase/firestore";
import {
  cacheJson,
  dbLimit,
  dbLimitHomepage,
  dbLimitStart,
  dbLimitThemes,
  DBTableCollections,
  DBTableOmnibus,
  DBTableStamps,
  DBTableStamps2Tags,
  DBTableTags,
  feRandomSort,
  hashFromConfig,
  localJson,
  localJsonStampLimitEnd,
  localJsonStampLimitStart,
  realJSONDB,
} from "../../configs";
import { randomSort } from "../../utils/math.utils";
import {
  CacheThemeStampsId,
  Collection,
  Stamp,
  TagModel,
} from "../../types/stamps";
import { FilterParamsModel } from "../../types/operations";

export class PlaygroundLogic {
  @observable list: "country" | "countryName" | "theme" | "hash" | "story";
  @observable tag: string | undefined = undefined;
  @observable pageIndex: number = 0;
  @observable stamps: Stamp[] = [];
  @observable stampsMap: Stamp[] = [];
  @observable stampsTagsIds: string[] = [];
  @observable stampsPreviewed: Stamp[] = [];
  @observable collectionPreviewed: Collection | undefined = undefined;
  @observable collectionPreviewedLoaded: boolean | undefined = false;
  @observable omnibusPreviewed: Collection | undefined = undefined;
  @observable omnibusPreviewedLoaded: boolean | undefined = false;
  @observable stampDetailed: Stamp | undefined = undefined;
  @observable stampDetailedTagsLoading: boolean = false;
  @observable tags: TagModel[] = [];
  @observable isOpenCollectionWords: boolean = false;
  @observable hash: string = "";
  @observable tagPreviewed: TagModel | undefined = undefined;
  @observable loading: boolean = true;
  @observable landing: boolean = false;
  @observable noMatchStamp: boolean = false;
  @observable noMatchTheme: boolean = false;
  @observable mapInitialized: boolean = false;
  appReady: boolean;

  constructor(private _logic: Logic) {
    this.stamps = [];
    this.stampsMap = [];
    this.stampsPreviewed = [];
    this.tags = [];
    this.isOpenCollectionWords = false;
    this.pageIndex = 0;
    this.list = "hash";
    this.stampsTagsIds = [];
    this.loading = false;
    this.appReady = false;
    this.landing = true;
    this.stampDetailedTagsLoading = false;
    this.noMatchStamp = false;
    this.noMatchTheme = false;

    makeAutoObservable(this);
  }

  async init(params: FilterParamsModel) {
    this.noMatchStamp = false;
    this.noMatchTheme = false;
    this.mapInitialized = false;

    if (this.appReady === false) {
      this.appReady = true;
      await this._fetchTags();
      if (params.object === "stamp" && params.id) {
        this._fetchStamp(params.id);
      } else {
        await this._fetchStamps(params);
      }
    }
  }

  toggleCollectionWords() {
    this.isOpenCollectionWords = !this.isOpenCollectionWords;
  }

  closeCollectionWords() {
    this.isOpenCollectionWords = false;
  }

  openCollectionWords() {
    this.isOpenCollectionWords = true;
  }

  async reload(reloadHash: string) {
    // TODO: get it back
    this.list = "hash";
    // this.list = "story";
    this.tag = undefined;
    this.tagPreviewed = undefined;
    this.noMatchStamp = false;
    this.noMatchTheme = false;
    if (hashFromConfig === "") {
      this.hash = reloadHash;
      console.log("reload 1", reloadHash);
    } else {
      console.log("reload 2", hashFromConfig);
      this.hash = hashFromConfig;
    }

    await this._fetchStamps({ country: "", tag: "", page: 0 });
    window.scrollTo && window.scrollTo(0, 0);
  }

  // TODO:
  async showStory() {
    this.list = "story";
  }

  async toggleDetailedStamp(stampId?: string) {
    if (stampId) {
      this.stampDetailedTagsLoading = true;
      this.stampDetailed = this.stamps.find((stamp) => stamp.id === stampId);
      // this._fetchBg();
      this._fetchStampTags();

      if (this.stampDetailed?.collectionId) {
        console.log("AHOJ collectionId", this.stampDetailed?.collectionId);

        // collectionId is in format cs_123-1, we need to get only cs_1
        // const collectionIdStart =
        //   this.stampDetailed?.collectionId.split("-")[0];
        this._fetchCollection(this.stampDetailed?.collectionId);
        this._fetchCollectionsStamps(this.stampDetailed?.collectionId);
      }

      if (this.stampDetailed?.omnibusId) {
        this._fetchOmnibus(this.stampDetailed?.omnibusId);
        this._fetchOmnibusStamps(this.stampDetailed?.omnibusId);
      }
    } else {
      this.stampDetailed = undefined;
      this.collectionPreviewed = undefined;
      this.collectionPreviewedLoaded = false;
      this.stampDetailedTagsLoading = false;
      this.noMatchStamp = false;
      this.noMatchTheme = false;
    }
  }

  async togglePreview(stampIds?: string[]) {
    const stampId = stampIds?.[0];
    if (!stampId) {
      console.log("togglePreview delete", stampId);
      this.stampsPreviewed = [];
      return;
    }

    console.log("Klik ZZZ", stampId, stampIds);
    console.log("klik batch", toJS(this.stamps));
    console.log("klik batch big", toJS(this.stampsMap));

    this.stampsPreviewed = this.stampsMap.filter((stamp) =>
      stampIds?.includes(stamp._id ?? "")
    );

    console.log("klik afgerere", toJS(this.stampsPreviewed));

    // if (this.stampsPreviewed.map((stamp) => stamp.id).includes(stampId)) {
    //   this.stampsPreviewed = this.stampsPreviewed.filter(
    //     (stamp) => stamp.id !== stampId
    //   );
    //   return;
    // } else {
    //   console.log("BBB");
    //   const stampDetailed = this.stamps.find((stamp) => stamp.id === stampId);
    //   if (stampDetailed) {
    //     this.stampsPreviewed = [stampDetailed];
    //   }
    // }
  }

  async _fetchJSONStamps() {
    const lastRaw = lastStamps as Stamp[];

    const at = lastRaw.map((stamp) => ({
      ...stamp,
    }));

    this.stamps = [...at].filter(
      (d, i) => i >= localJsonStampLimitStart && i < localJsonStampLimitEnd
    );
  }

  async _fetchStampTags() {
    const db = getFirestore();
    const colRefStamps2Tags = collection(db, DBTableStamps2Tags);
    const colRefTags = collection(db, DBTableTags);

    const q = query(
      colRefStamps2Tags,
      where("stamp_id", "==", this.stampDetailed?.id)
    );

    getDocs(q)
      .then((snapshot) => {
        let tags: string[] = [];
        snapshot.forEach((doc) => {
          tags.push(doc.data().tag_id);
        });

        if (tags.length > 0) {
          const q = query(colRefTags, where("tag_id", "in", tags));

          getDocs(q)
            .then((snapshot) => {
              let themeTags: TagModel[] = [];
              snapshot.forEach((doc) => {
                themeTags.push({
                  tag_id: doc.data().tag_id ?? "",
                  label: doc.data().label ?? "",
                  count: doc.data().count,
                  level: doc.data().level,
                });
              });

              // this._checkCorrectThemes(themeTags);

              this.stampDetailed = {
                ...this.stampDetailed,
                height: this.stampDetailed?.height ?? 0,
                width: this.stampDetailed?.width ?? 0,
                id: this.stampDetailed?.id ?? "",
                _themeTags: themeTags,
              };
              this.stampDetailedTagsLoading = false;
            })
            .catch((error) => {
              console.log("Error getting documents - tags: ", error);
              this.stampDetailedTagsLoading = false;
            });
        } else {
          this.stampDetailedTagsLoading = false;
        }
      })
      .catch((error) => {
        this.stampDetailedTagsLoading = false;
        console.log("Error getting documents - tags: ", error);
      });

    this.stampDetailedTagsLoading = false;
  }

  // if there is only one tag, find it in children if items in variable menuItems and find its parent
  _checkCorrectThemes(themeTags: TagModel[]) {
    let missingParentTag = undefined;
    if (themeTags.length === 1) {
      menutItems.forEach((item) => {
        if (item.children.length > 0) {
          item.children?.forEach((child) => {
            if (child.tag === themeTags[0].tag_id) {
              missingParentTag = item.tag;
            }
          });
        }
      });
    }
    if (missingParentTag) {
      this.addThemeTagSeparate(this.stampDetailed?.id ?? "", missingParentTag);
    }
  }

  async fetchStampsAndUpdateCollectionIdxx() {
    const country = "sg";
    const db = getFirestore();
    const colRefCollections = collection(db, "collections");

    try {
      const querySnapshot = await getDocs(query(colRefCollections));

      querySnapshot.forEach(async (doc) => {
        try {
          console.log("Deleting", doc.data().label);
          if (
            doc.data().collection_id[0] === country[0] &&
            doc.data().collection_id[1] === country[1] &&
            doc.data().collection_id[2] === "_"
          ) {
            await deleteDoc(doc.ref);
            console.log("Successfully deleted", doc.data().label);
          }
        } catch (deleteError) {
          console.error("Error deleting document:", deleteError);
        }
      });
    } catch (error) {
      console.error("Error getting documents:", error);
    }
  }

  async onDeleteYearCountry() {
    const countryNameCode = "united_kingdom_of_great_britain_northern_ireland";
    const year = 2020;
    console.log("DELETE STAYE" + countryNameCode + " " + year);
    // delete all stamps which have country and year
    const db = getFirestore();
    const colRefStamps = collection(db, DBTableStamps);

    const q = query(
      colRefStamps,
      where("countryNameCode", "==", countryNameCode),
      where("year", "==", Number(year))
    );

    const querySnapshot = await getDocs(q);

    const batch = writeBatch(db);
    querySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
      console.log("deleting", doc.data().name);
    });

    // delete all items in stamps2tags which have stamp_id from previous query
    const colRefStamps2Tags = collection(db, DBTableStamps2Tags);
    const q2 = query(colRefStamps2Tags);
    const querySnapshot2 = await getDocs(q2);

    const batch2 = writeBatch(db);
    querySnapshot2.forEach((doc) => {
      if (querySnapshot.docs.find((d) => d.id === doc.data().stamp_id)) {
        batch2.delete(doc.ref);
      }
    });

    await batch.commit();
    await batch2.commit();
  }

  async onGenerateCollections() {
    const country = "sg";

    console.log("fetchStampsAndUpdateCollectionId - start");
    const db = getFirestore();
    const colRefStamps = collection(db, DBTableStamps);

    const q = query(colRefStamps, where("country", "==", country));
    const querySnapshotCountry = await getDocs(q);

    let stamps: Stamp[] = [];
    querySnapshotCountry.forEach((doc) => {
      const targetName = doc.data().nameEn ?? doc.data().name;
      console.log("***name", targetName, doc.data().id);
      const splitedTargetName5 = targetName.split("$")[0].trim();
      const splitedTargetName4 = splitedTargetName5.split(".")[0].trim();
      const splitedTargetName3 = splitedTargetName4.split("(")[0].trim();
      const splitedTargetName2 = splitedTargetName3.split("-")[0].trim();
      const splitedTargetName1 = splitedTargetName2.split("–")[0].trim();
      const splitedTargetName = splitedTargetName1.split(":")[0].trim();

      stamps.push({
        id: doc.data().id,
        name: splitedTargetName,
        width: doc.data().width!,
        height: doc.data().height!,
        collectionId: doc.data().collectionId,
      });
    });

    const stampsWithSameName = stamps.filter(
      (stamp) => stamps.filter((d) => d.name === stamp.name).length > 1
    );

    // sort stampsWithSameName by name
    stampsWithSameName.sort((a, b) =>
      (a.name ?? "") > (b.name ?? "") ? 1 : -1
    );

    let lastStampName = "";
    let collectionIndex = 0;
    let collectionId = "";

    let promises: any = [];
    let collectionIdsStamps: any = [];

    stampsWithSameName.forEach(async (stamp, j) => {
      if (lastStampName !== stamp.name) {
        collectionIndex++;
        collectionId = country + "_" + collectionIndex;

        // CREATE COLLECTION
        const colRefCollections = collection(db, "collections");
        const x = addDoc(colRefCollections, {
          label: stamp.name,
          count: 0,
          collection_id: collectionId,
        });
        promises.push(x);
        console.log(j, "new col", stamp.name, collectionId);
      }
      lastStampName = stamp.name ?? "";
      collectionIdsStamps[stamp.id] = { id: country + "_" + collectionIndex };
    });

    await Promise.all(promises);

    console.log("collectionIdsStamps", collectionIdsStamps);
    const colRef = collection(db, DBTableStamps);
    const querySnapshot = await getDocs(colRef);
    const batch = writeBatch(db);
    querySnapshot.forEach((doc) => {
      const docRef: DocumentReference = doc.ref;

      if (collectionIdsStamps[doc.data().id]) {
        batch.update(docRef, {
          collectionId: collectionIdsStamps[doc.data().id].id,
        });

        console.log(
          "add collections",
          doc.data().id,
          collectionIdsStamps[doc.data().id].id
        );
      }
    });

    await batch.commit();
  }

  async _fetchTags() {
    const db = getFirestore();
    const colRef = collection(db, DBTableTags);

    const q = query(colRef);
    await getDocs(q)
      .then((snapshot) => {
        let tags: TagModel[] = [];
        snapshot.forEach((doc) => {
          tags.push({
            tag_id: doc.data().tag_id,
            label: doc.data().label,
            count: doc.data().count,
            level: doc.data().level,
            geolocated: doc.data().geolocated,
          });
        });

        this.tags = tags;
      })
      .catch((error) => {
        console.error("Error getting documents - tags: ", error);
      });
  }

  async loadStamps(params: FilterParamsModel) {
    // this.tagPreviewed = params.tag;
    this._fetchTags();
    this._fetchStamps(params);
  }

  async handleLocalSnapshotMini(stampsLocalMini: Stamp[]) {
    console.log("Type of stampsLocalMini:", typeof stampsLocalMini);
    console.log("Is stampsLocalMini an array?", Array.isArray(stampsLocalMini));
    console.log("stampsLocalMini:", stampsLocalMini);
    console.log("stampsLocalMini length:", stampsLocalMini.length);
    let formatedStamps: Stamp[] = toJS(stampsLocalMini);
    let miniStamps: Stamp[] = [];
    console.log("AAAMAP hajde", miniStamps);
    formatedStamps.forEach((stamp, i) => {
      const imgLocal =
        stamp.imgLocal && stamp.imgLocal !== ""
          ? "/stamps/" +
            stamp.country +
            "/" +
            stamp.imgLocal
              .replace("/stamps/", "stamps/")
              .replace("stamps/" + stamp.country + "/", "")
          : undefined;
      const imgLocalLarge =
        stamp.imgLocalLarge && stamp.imgLocalLarge !== ""
          ? "/stamps/" +
            stamp.country +
            "/" +
            stamp.imgLocalLarge
              .replace("/stamps/", "stamps/")
              .replace("stamps/" + stamp.country + "/", "")
          : undefined;
      miniStamps.push({
        ...stamp,
        imgLocal: imgLocal,
        imgLocalLarge: imgLocalLarge,
      });
      return;
    });
    this.stampsMap = formatedStamps;
    console.log("AAAMAP FINFIFNI", toJS(this.stampsMap));
  }

  async handleLocalSnapshot(stampsLocal: Stamp[]) {
    let stamps: Stamp[] = [];

    stampsLocal.sort((a, b) => ((a._id ?? "") > (b._id ?? "") ? 1 : -1));

    // filter only stamps format=Stamp
    stampsLocal = stampsLocal.filter((stamp) => stamp.format === "Stamp");

    stampsLocal.forEach((stamp) => {
      const imgLocal =
        stamp.imgLocal && stamp.imgLocal !== ""
          ? "/stamps/" +
            stamp.country +
            "/" +
            stamp.imgLocal
              .replace("/stamps/", "stamps/")
              .replace("stamps/" + stamp.country + "/", "")
          : undefined;
      const imgLocalLarge =
        stamp.imgLocalLarge && stamp.imgLocalLarge !== ""
          ? "/stamps/" +
            stamp.country +
            "/" +
            stamp.imgLocalLarge
              .replace("/stamps/", "stamps/")
              .replace("stamps/" + stamp.country + "/", "")
          : undefined;
      stamps.push({
        ...stamp,
        imgLocal: imgLocal,
        imgLocalLarge: imgLocalLarge,
      });
    });

    console.log("LOCAL", stamps);

    // NOT GOOD PLACE TO FIX
    let tempStamps;
    if (this.hash === "") {
      tempStamps = stamps.slice(0, dbLimitHomepage);
    } else {
      tempStamps = stamps;
    }

    this.stamps = feRandomSort ? randomSort(tempStamps) : tempStamps;

    console.log("LOCAL 2", toJS(this.stamps));
  }

  async handleSnapshot(snapshot: QuerySnapshot<DocumentData>) {
    let stamps: Stamp[] = [];

    snapshot.docs.map(async (doc) => {
      const imgLocal =
        doc.data().imgLocal && doc.data().imgLocal !== ""
          ? "/stamps/" +
            doc.data().country +
            "/" +
            doc
              .data()
              .imgLocal.replace("/stamps/", "stamps/")
              .replace("stamps/" + doc.data().country + "/", "")
          : undefined;
      const imgLocalLarge =
        doc.data().imgLocalLarge && doc.data().imgLocalLarge !== ""
          ? "/stamps/" +
            doc.data().country +
            "/" +
            doc
              .data()
              .imgLocalLarge.replace("/stamps/", "stamps/")
              .replace("stamps/" + doc.data().country + "/", "")
          : undefined;

      stamps.push({
        ...this.docDataToStamp(doc),
        imgLocal: imgLocal,
        imgLocalLarge: imgLocalLarge,
      });
    });

    this.stamps = feRandomSort ? randomSort(stamps) : stamps;
    console.log("NORMAL 2", toJS(this.stamps));
  }

  docDataToStamp(doc: DocumentData) {
    const stamp: Stamp = {
      id: doc.data().id,
      _id: doc.data()._id,
      _disabledImg: doc.data()._disabledImg,
      geometa: doc.data().geometa,
      biometa: doc.data().biometa,
      sku: doc.data().sku,
      collectionId: doc.data().collectionId,
      omnibusId: doc.data().omnibusId,
      annotation: doc.data().annotation,
      annotationEn: doc.data().annotationEn,
      author: doc.data().author,
      awards: doc.data().awards,
      awardsEn: doc.data().awardsEn,
      designer: doc.data().designer,
      engraver: doc.data().engraver,
      illustrator: doc.data().illustrator,
      noteEn: doc.data().noteEn,
      paper: doc.data().paper,
      perforation: doc.data().perforation,
      motive: doc.data().motive,
      description: doc.data().description,
      themeDescription: doc.data().themeDescription,
      themeDescriptionEn: doc.data().themeDescriptionEn,
      themeDescriptionShortEn: doc.data().themeDescriptionShortEn,
      themeTitle: doc.data().themeTitle,
      themeTitleEn: doc.data().themeTitleEn,
      formEn: doc.data().formEn,
      printTechniqueEn: doc.data().printTechniqueEn,
      height: doc.data().height,
      width: doc.data().width,
      format: doc.data().format,
      name: doc.data().name,
      nameEn: doc.data().nameEn,
      country: doc.data().country,
      countryName: doc.data().countryName,
      countryNameCode: doc.data().countryNameCode,
      date: doc.data().date,
      dateWithdrawn: doc.data().dateWithdrawn,
      expiryDate: doc.data().expiryDate,
      year: doc.data().year,
      yearFrom: doc.data().yearFrom,
      yearTo: doc.data().yearTo,
      form: doc.data().form,
      countInList: doc.data().countInList,
      countInSeries: doc.data().countInSeries,
      print: doc.data().print,
      printTechnique: doc.data().printTechnique,
      bonding: doc.data().bonding,
      bondingEn: doc.data().bondingEn,
      emission: doc.data().emission,
      emissionString: doc.data().emissionString,
      img: doc.data().img,
      imgLocal: doc.data().imgLocal,
      imgLocalLarge: doc.data().imgLocalLarge,
      imgsLocalList: doc.data().imgsLocalList,
      imgsList: doc.data().imgsList,
      url: doc.data().url,
      urlEn: doc.data().urlEn,
      wns: doc.data().wns,
      themes: doc.data().themes,
      themeTags: doc.data().themeTags,
      imgLarge: doc.data().imgLarge,
      imgSmall: doc.data().imgSmall,
      collectionTags: doc.data().collectionTags,
      labels: doc.data().labels,
      color: doc.data().color,
      nominal: doc.data().nominal,
      price: doc.data().price,
      photo: doc.data().photo,
      imgHeight: doc.data().imgHeight,
      imgWidth: doc.data().imgWidth,
      issuedIn: doc.data().issuedIn,
      denomination: doc.data().denomination,
      reprint: doc.data().reprint,
      catalogs: doc.data().catalogs,
      additionalInfo: doc.data().additionalInfo,
      artists: doc.data().artists,
      plots: doc.data().plots,
      series: doc.data().series,
      gum: doc.data().gum,
      emissionType: doc.data().emissionType,
    };
    console.log("STAMP", stamp);
    return stamp;
  }

  setTagPageIndex(i: number) {
    console.log("SETPAGE INDEX, fetch stamps", i);
    this._fetchStamps({ tag: this.tagPreviewed?.tag_id, page: i });
  }

  onCollectionImageClick(id: string) {
    if (this.stampDetailed) {
      const stampClicked = this.stampDetailed?._withinCollection?.find(
        (stamp) => stamp.id === id
      );

      const actualWithin = this.stampDetailed?._withinCollection;

      if (stampClicked) {
        this.stampDetailed = {
          ...stampClicked,
          _withinCollection: actualWithin,
        };
        this._fetchStampTags();
      }
    }
  }

  onOmnibusImageClick(id: string) {
    if (this.stampDetailed) {
      const stampClicked = this.stampDetailed?._withinOmnibus?.find(
        (stamp) => stamp.id === id
      );

      const actualWithin = this.stampDetailed?._withinOmnibus;

      if (stampClicked) {
        this.stampDetailed = {
          ...stampClicked,
          _withinOmnibus: actualWithin,
        };
        this._fetchStampTags();
      }
    }
  }

  async _fetchStamp(id: string) {
    const db = getFirestore();
    const q = query(
      collection(db, DBTableStamps),
      where("timestampField", "==", id)
    );
    const snapshot = await getDocs(q);
    this.stampDetailed = snapshot.docs.map((doc) =>
      this.docDataToStamp(doc)
    )[0];

    if (this.stampDetailed === undefined) {
      this.noMatchStamp = true;
    }

    this._fetchStampTags();
    if (this.stampDetailed.collectionId) {
      const collectionIdStart = this.stampDetailed.collectionId;
      this._fetchCollection(collectionIdStart);
      this._fetchCollectionsStamps(this.stampDetailed?.collectionId);
    }

    if (this.stampDetailed.omnibusId) {
      this._fetchOmnibus(this.stampDetailed.omnibusId);
      this._fetchOmnibusStamps(this.stampDetailed?.omnibusId);
    }
  }

  async _fetchStamps(params: FilterParamsModel) {
    this.loading = true;
    this.stampDetailed = undefined;
    this.collectionPreviewed = undefined;
    this.landing =
      params.country === "" &&
      params.countryName === "" &&
      params.tag === "" &&
      this.hash === "";

    if (localJson) {
      this._fetchJSONStamps();
      return;
    }

    const db = getFirestore();
    const colRefStamps = collection(db, DBTableStamps);

    let q;
    if (params.tag) {
      console.log("paramsTAG", params);
      this.list = "theme";
      this.tag = params.tag;
      this.pageIndex = params.page ?? 0;
      //   this.stampsTagsIds = [];
      this.noMatchTheme = false;

      if (this.tagPreviewed?.tag_id !== params.tag) {
        this.tagPreviewed = this.tags.find((tag) => tag.tag_id === params.tag);
        this.stampsTagsIds = await this._requestStampsIdByTag(params.tag);
      }

      if (this.stampsTagsIds.length === 0) {
        this.noMatchTheme = true;
      }

      if (
        this.tagPreviewed &&
        this.stampsTagsIds &&
        this.stampsTagsIds.length > 0
      ) {
        this._requestStampsDetail(
          this.tagPreviewed?.tag_id,
          this.stampsTagsIds,
          this.pageIndex
        );

        console.log("AAAMAP x");
        if (this.tagPreviewed.geolocated && this.pageIndex === 0) {
          console.log(
            "AAAMAP aa",
            this.tagPreviewed.geolocated,
            this.pageIndex
          );
          this._requestStampsMap(this.tagPreviewed?.tag_id);
        } else {
          console.log("AAAMAP vv");
        }
      } else {
        this.loading = false;
      }
    } else if (params.country && params.country !== "") {
      this.list = "country";
      q = query(
        colRefStamps,
        where("country", "==", params.country),
        orderBy("id", "asc"),
        limit(dbLimit),
        startAt(dbLimitStart)
      );

      if (q !== undefined) {
        getDocs(q)
          .then((snapshot) => {
            this.handleSnapshot(snapshot);
            return;
          })
          .catch((error) => {
            console.error("Error getting documents stamps: ", error);
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    } else {
      this._requestStampsDetailByHash(this.hash);
    }
    this.mapInitialized = true;
  }

  /**
   * Fetches all stamps from stamps table where collectionId is given
   */
  private _fetchCollectionsStamps(collectionId: string) {
    const db = getFirestore();
    const colRefStamps = collection(db, DBTableStamps);

    console.log("in fetc coll stamps", collectionId);

    // if not filter Souvernir sheets, consider showing it also
    const q = query(
      colRefStamps,
      where("collectionId", "==", collectionId)
      //   where("format", "==", "Stamp")
    );

    getDocs(q)
      .then((snapshot) => {
        let stamps: Stamp[] = [];
        snapshot.forEach((doc) => {
          stamps.push(this.docDataToStamp(doc));
        });

        stamps.sort((a, b) =>
          a.imgWidth && a.imgHeight && b.imgWidth && b.imgHeight
            ? a.imgWidth / a.imgHeight - b.imgWidth / b.imgHeight
            : 0
        );

        this.stampDetailed = {
          ...this.stampDetailed!,
          id: this.stampDetailed?.id!,
          width: this.stampDetailed?.width!,
          height: this.stampDetailed?.height!,
          _withinCollection: stamps,
        };

        console.log("stamp det", this.stampDetailed);
      })
      .catch((error) => {
        console.error("Error getting documents - stamps: ", error);
      });
  }

  private _fetchOmnibusStamps(omnibusId: string) {
    const db = getFirestore();
    const colRefStamps = collection(db, DBTableStamps);

    const q = query(colRefStamps, where("omnibusId", "==", omnibusId));

    getDocs(q)
      .then((snapshot) => {
        let stamps: Stamp[] = [];
        snapshot.forEach((doc) => {
          stamps.push(this.docDataToStamp(doc));
        });

        // sort stamps by ratio imgWidth/imgHeight
        stamps.sort((a, b) =>
          a.imgWidth && a.imgHeight && b.imgWidth && b.imgHeight
            ? a.imgWidth / a.imgHeight - b.imgWidth / b.imgHeight
            : 0
        );

        this.stampDetailed = {
          ...this.stampDetailed!,
          id: this.stampDetailed?.id!,
          width: this.stampDetailed?.width!,
          height: this.stampDetailed?.height!,
          _withinOmnibus: stamps,
        };
      })
      .catch((error) => {
        console.error("Error getting documents - stamps: ", error);
      });
  }

  /**
   * Fetches information from collection table for given collectionId
   * and store it into previewedCollection in state
   */
  private _fetchCollection(collectionId: string) {
    const db = getFirestore();
    const colRefCollections = collection(db, DBTableCollections);

    console.log("fetch colle", collectionId);
    const q = query(
      colRefCollections,
      where("collection_id", "==", collectionId)
    );

    console.log("XXXXX");

    getDocs(q)
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log("wgu ininin");
          this.collectionPreviewed = {
            id: doc.data().collection_id,
            label: doc.data().label,
          };
          console.log("LOADED TRUE");
          this.collectionPreviewedLoaded = true;
        });
        console.log("wgu2", this.collectionPreviewedLoaded);
      })
      .catch((error) => {
        console.error("Error getting documents - collections: ", error);
      });
  }

  private _fetchOmnibus(omnibusId: string) {
    const db = getFirestore();
    const colRefCollections = collection(db, DBTableOmnibus);

    const q = query(colRefCollections, where("omnibus_id", "==", omnibusId));

    getDocs(q)
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          this.omnibusPreviewed = {
            id: doc.data().omnibus_id,
            label: doc.data().label,
          };
          this.omnibusPreviewedLoaded = true;
        });
      })
      .catch((error) => {
        console.error("Error getting documents - omnibus: ", error);
      });
  }

  private _fetchBgAll() {
    const db = getFirestore();
    const colRefStamps2Colors = collection(db, "stamps2colors");
    const qStamps2Colors = query(colRefStamps2Colors);

    getDocs(qStamps2Colors)
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          this.stamps = this.stamps.map((stamp) => {
            if (stamp.id === doc.data().stamp_id) {
              return {
                ...stamp,
                _bgColor: doc.data().color,
              };
            } else {
              return stamp;
            }
          });
        });
      })
      .catch((error) => {
        console.error("Error getting documents - colors: ", error);
      });
  }

  // Function get stamp_id and label string.
  // New label will be part of atribute labels in stamps table.
  // Stamp will be updated with new array of labels and saved to db.
  async addLabel(label: string, id: string) {
    // Fetch current tag count from table stamps2tags for tag and update it
    const db = getFirestore();
    const qq = query(collection(db, DBTableStamps), where("id", "==", id));
    const snapshot = await getDocs(qq);
    const currentStampLabels = snapshot.docs[0].data().labels ?? [];

    const updatedLabels = [...currentStampLabels, label];

    const colRef = collection(db, DBTableStamps);

    const q = query(colRef, where("id", "==", id));
    const querySnapshot = await getDocs(q);
    const batch = writeBatch(db);

    querySnapshot.forEach((doc) => {
      const docRef: DocumentReference = doc.ref;

      batch.update(docRef, {
        labels: updatedLabels,
      });
    });

    await batch.commit();
  }

  // Function update stamp themeTags in stamps table.
  // It add given tag to existing themeTags array.
  async updateStampThemeTags(id: string, tag: string) {
    console.log("updateStampThemeTags", id, tag);
    const db = getFirestore();
    const colRef = collection(db, DBTableStamps);

    const q = query(colRef, where("_id", "==", id));
    const querySnapshot = await getDocs(q);
    const batch = writeBatch(db);

    querySnapshot.forEach((doc) => {
      const docRef: DocumentReference = doc.ref;

      const updatedThemeTags = [...doc.data().themeTags, tag];
      console.log("updatedThemeTags", updatedThemeTags);

      batch.update(docRef, {
        themeTags: updatedThemeTags,
      });
    });

    await batch.commit();
  }

  async addThemeTagSeparate(id: string, tag: string) {
    const db = getFirestore();
    const colRef = collection(db, DBTableStamps2Tags);

    try {
      // Use await for the addDoc function to ensure it completes before proceeding
      await addDoc(colRef, { tag_id: tag, stamp_id: id });

      // Fetch current tag count from table stamps2tags for tag and update it
      const q = query(
        collection(db, "stamps2tags"),
        where("tag_id", "==", tag)
      );
      const snapshot = await getDocs(q);
      const tagCount = snapshot.size;

      const colRefTags = collection(db, DBTableTags);
      const querySnapshot = await getDocs(colRefTags);
      const batch = writeBatch(db);

      querySnapshot.forEach((doc) => {
        const docRefTags: DocumentReference = doc.ref;

        if (doc.data().tag_id === tag) {
          batch.update(docRefTags, {
            count: tagCount,
          });
        }
      });

      await batch.commit();
      console.log("Tags updated successfully");
    } catch (error) {
      console.error("Error adding collection tag separate:", error);
    }
  }

  async saveDimensions(
    ximages: { id: string; width: number; height: number; imgLocal: string }[]
  ) {
    console.log("DIMENs", ximages);
    const db = getFirestore();
    const colRef = collection(db, DBTableStamps);
    const images = ximages.map((d) => ({
      ...d,
      imgLocal: d.imgLocal.replace("http://localhost:3000/", ""),
    }));

    try {
      const querySnapshot = await getDocs(colRef);
      const batch = writeBatch(db);

      querySnapshot.forEach((doc) => {
        const docRef: DocumentReference = doc.ref;
        const img = images.find((d) => d.id === doc.data().xid);

        console.log(
          "saving",
          doc.data().id,
          img?.imgLocal,
          doc.data().imgLocal,
          img?.width,
          img?.height
        );

        // const imgLo = img?.imgLocal.split("/");
        // const imgLo2 = "/stamps/hu/" + imgLo?.[imgLo.length - 1];

        if (img) {
          batch.update(docRef, {
            imgWidth: img.width,
            imgHeight: img.height,
          });
        }
      });

      await batch.commit();
    } catch (error) {
      console.error("Error saving width and height:", error);
    }
  }

  async loadTags(stampId?: string) {
    const db = getFirestore();
    const colRef = collection(db, "stamps2tags");

    const q = query(colRef, where("stamp_id", "==", stampId));
    const querySnapshot = await getDocs(q);

    let tags: string[] = [];
    querySnapshot.forEach((doc) => {
      tags.push(doc.data().tag_id);
    });

    this.stamps = this.stamps.map((s) => {
      return s.id === stampId
        ? {
            ...s,
            collectionTags: tags,
          }
        : s;
    });
  }

  async addColor(color: string, id?: string) {
    if (id) {
      const db = getFirestore();
      const colRef = collection(db, "stamps2colors");
      addDoc(colRef, { color: color, stamp_id: id });
      console.log("add color", color, id);
    }
  }

  // async addCollectionId(collectionId: string, id?: string) {
  //   console.log("add collectionId", collectionId, id);
  //   if (id) {
  //     const db = getFirestore();
  //     await updateDoc(doc(db, DBTableStamps, id), {
  //       collectionId: collectionId,
  //     });
  //   }
  // }

  getNationalCatalogIdByCountry(
    country: string = "",
    catalogs: string = "",
    xstamp: Stamp
  ) {
    console.log("getNationalCatalogIdByCountry", country, catalogs);
    const arrCatalogs = catalogs.split(",");
    let result = "";
    if (country === "poland") {
      const cat = arrCatalogs.find((d) => d.trim().startsWith("Pol"));
      result = cat?.replace("Pol:", "").replaceAll(" ", "").toLowerCase() ?? "";
    } else if (country === "czechoslovakia") {
      const cat = arrCatalogs.find((d) => d.trim().startsWith("POF"));
      result = cat?.replace("POF:", "").replaceAll(" ", "").toLowerCase() ?? "";
    } else if (country === "slovenia") {
      // NOTHING
    } else if (country === "norway") {
      const cat = arrCatalogs.find((d) => d.trim().startsWith("Nor"));
      result = cat?.replace("Nor:", "").replaceAll(" ", "").toLowerCase() ?? "";
      console.log("FFFFFINDED", result);
    } else if (country === "czech_republic") {
      const cat = arrCatalogs.find((d) => d.trim().startsWith("POF"));
      result = cat?.replace("POF:", "").replaceAll(" ", "").toLowerCase() ?? "";
      if (result.length === 5) {
        result = result.replaceAll("cz", "cz0");
      }
    } else if (country === "slovakia") {
      const cat = arrCatalogs.find((d) => d.trim().startsWith("POF"));
      result = String(
        parseInt(
          cat?.replace("POF:", "").replace("SK", "").replaceAll(" ", "") ?? ""
        )
      );
    }

    return result;
  }

  isSerialNumberVariant = (stamp: Stamp) => {
    const arrCatalogs = stamp.catalogs?.split(",");
    const serialNumber = arrCatalogs?.find((d: any) =>
      d.trim().startsWith("Sn:")
    );
    const numberWithoutCountry =
      serialNumber?.split(" ")[serialNumber?.split(" ").length - 1];
    // if numberWithoutCountry contains some alphabet letter, it is a variant
    console.log("numberWithoutCountry: ", numberWithoutCountry);
    const isVariant =
      /[a-zA-Z]/.test(numberWithoutCountry ?? "") && stamp.format === "Stamp";

    console.log(
      "test",
      /[a-zA-Z]/.test(numberWithoutCountry ?? "") ? "true" : "false"
    );
    console.log("stamp.format", stamp.format);
    return isVariant;
  };

  addParametersToStampObject = (stamp: Stamp, oldStamp: Stamp) => {
    // console.log("addParametersToStampObject", stamp, oldStamp);
    if (oldStamp.countInList) {
      stamp.countInList = oldStamp.countInList;
    }
    if (oldStamp.countInSeries) {
      stamp.countInSeries = oldStamp.countInSeries;
    }
    if (oldStamp.paper) {
      stamp.paper = oldStamp.paper;
    }
    // bonding
    if (oldStamp.bonding) {
      stamp.bonding = oldStamp.bondingEn;
    }
    // issuedIn
    if (oldStamp.issuedIn) {
      stamp.issuedIn = oldStamp.issuedIn;
    }
    // illustrator
    if (oldStamp.illustrator) {
      stamp.illustrator = oldStamp.illustrator;
    }
    // engraver
    if (oldStamp.engraver) {
      stamp.engraver = oldStamp.engraver;
    }
    // motive
    if (oldStamp.motive) {
      stamp.motive = oldStamp.motive;
    }
    // noteEn
    if (oldStamp.noteEn) {
      stamp.note = oldStamp.noteEn;
    }
    // themeTitleEn
    if (oldStamp.themeTitleEn) {
      stamp.themeTitle = oldStamp.themeTitleEn;
    }
    // themeDescriptionEn
    if (oldStamp.themeDescriptionEn) {
      stamp.themeDescription = oldStamp.themeDescriptionEn;
    }
    // awardsEn
    if (oldStamp.awardsEn) {
      stamp.awards = oldStamp.awardsEn;
    }
    // wns
    if (oldStamp.wns) {
      stamp.wns = oldStamp.wns;
    }
    // urlEn to originalUrl
    if (oldStamp.urlEn) {
      stamp.originalUrl = oldStamp.urlEn;
    }
    // themeDescriptionShortEn
    if (oldStamp.themeDescriptionShortEn) {
      stamp.themeDescriptionShort = oldStamp.themeDescriptionShortEn;
    }
    // labels
    if (oldStamp.labels) {
      stamp.labels = oldStamp.labels;
    }
    // descriptionEn
    if (oldStamp.descriptionEn) {
      stamp.description = oldStamp.descriptionEn;
    }
    // designer
    if (oldStamp.designer && oldStamp.country === "cz") {
      stamp.designers = oldStamp.designer;
    }
    // imgsListq
    stamp.imgsList = [stamp.img ?? "", oldStamp.img ?? ""];
    stamp.imgsLocalList = [stamp.imgLocal ?? "", oldStamp.imgLocal ?? ""];
    stamp.oldId = oldStamp.id;
  };

  async addJSONToDb() {
    const db = getFirestore();
    const colRefXStamps = collection(db, DBTableStamps);
    const colRefXStamps2XTags = collection(db, DBTableStamps2Tags);
    const colRefXCollections = collection(db, DBTableCollections);
    const colRefStamps = collection(db, "stamps");
    const collections = [];

    const stampsRaw = lastStamps as Stamp[];
    const stampsMapped = stampsRaw.map((stamp, i) => {
      const id = generateRandomString(12);

      if (stamp.series) {
        stamp.collectionId =
          stamp.countryNameCode + "_" + makeTagId(stamp.series);
      }
      return {
        ...stamp,
        id: id,
        _id: id,
        timestampField: id,
      };
    });

    for (const stamp of stampsMapped) {
      // FIND DUPLICATE
      //   console.log(
      //     "looking for",
      //     stamp.name,
      //     stamp.countryNameCode,
      //     stamp.catalogs
      //   );
      const oldDuplicateId = this.getNationalCatalogIdByCountry(
        stamp.countryNameCode,
        stamp.catalogs,
        stamp
      );
      if (
        (oldDuplicateId !== "" && stamp.format === "Stamp") ||
        (stamp.countryNameCode === "estonia" && stamp.format === "Stamp") ||
        (stamp.countryNameCode === "hungary" && stamp.format === "Stamp")
      ) {
        console.log("oldDuplicateId:" + oldDuplicateId + ";", stamp);
        // Fetch stamp from db table stamp, where id = oldDuplicateId

        let q;
        if (stamp.countryNameCode === "hungary") {
          console.log(
            "params q",
            parseInt(stamp.nominal?.replaceAll(",", "") ?? "") + " HUF",
            stamp.width,
            stamp.height,
            stamp.emission,
            this.convertDateToDisplayFormat(stamp.date ?? "")
          );
          const baseQuery = query(
            colRefStamps,
            where(
              "nominal",
              "==",
              parseInt(stamp.nominal?.replaceAll(",", "") ?? "") + " HUF"
            ),
            where("country", "==", "hu"),
            where("width", "==", stamp.height),
            where("height", "==", stamp.width),
            where(
              "date",
              "==",
              this.convertDateToDisplayFormat(stamp.date ?? "")
            )
          );
          q = baseQuery;

          if (stamp.emission) {
            q = query(baseQuery, where("emission", "==", stamp.emission));
          } else {
            q = baseQuery;
          }
        } else if (stamp.countryNameCode === "estonia") {
          q = query(
            colRefStamps,
            where("nominal", "==", stamp.nominal?.substring(0, 4) + " €"),
            where("emission", "==", stamp.emission),
            where(
              "date",
              "==",
              stamp.date?.substring(8, 10) +
                "." +
                stamp.date?.substring(5, 7) +
                "." +
                stamp.date?.substring(0, 4)
            )
          );
        } else {
          q = query(colRefStamps, where("id", "==", oldDuplicateId));
        }
        try {
          console.log("start find old stamp", q);
          const docSnapshot = await getDocs(q);
          if (!docSnapshot.empty) {
            const oldStamp = docSnapshot.docs[0].data();

            console.log("AAAAAAAAAAAAAAAA found MATCHd", oldStamp);
            this.addParametersToStampObject(stamp, oldStamp as Stamp);
          } else {
            console.log("NNNNNNNNNNNNNNNnn");
          }
        } catch (error) {
          console.error("Error getting documents - stamps: ", error);
        }
      }

      const isReprintVariant =
        stamp.countryNameCode === "latvia" && this.isSerialNumberVariant(stamp)
          ? "Reprint"
          : "";
      console.log("isReprintVariant", isReprintVariant);

      // ADD TO XSTAMPS2XTAGS
      if (stamp.themes && stamp.format === "Stamp") {
        for (const tag of stamp.themes) {
          realJSONDB &&
            (await addDoc(colRefXStamps2XTags, {
              tag_id: makeTagId(tag),
              stamp_id: stamp.id,
            }));
          console.log("add-xS2xT", tag, stamp.id);
        }
        stamp.themeTags = stamp.themes.map((d) => makeTagId(d));
      }
      stamp.format = stamp.format + isReprintVariant;
      console.log("AFTER UPDATE", stamp.format);

      // ADD TO XSTAMPS
      realJSONDB && (await addDoc(colRefXStamps, stamp));
      console.log("add-xsamp", stamp.id, stamp);

      if (stamp.series) {
        collections.push(stamp.series);
      }
    }

    // ADD TO XCOLLECTIONS
    const collectionsUnique = [...collections].filter(
      (value, index, self) => self.indexOf(value) === index
    );
    console.log("collectionsUnique", collectionsUnique);

    if (realJSONDB) {
      for (const collection of collectionsUnique) {
        const queryGetXColl = query(
          colRefXCollections,
          where("collection_id", "==", makeTagId(collection))
        );
        const snapshot = await getDocs(queryGetXColl);
        if (snapshot.empty) {
          await addDoc(colRefXCollections, {
            label: collection,
            collection_id: makeTagId(collection),
          });
        }
      }
    }
  }

  duplicateParameterToConsoleString() {
    const stampsRaw = lastStamps as Stamp[];

    let lastName = "";
    let stampBeforeNextStampWithName: Stamp | undefined = undefined;

    const stampsArray = stampsRaw.map((stamp, i) => {
      if (stamp.name !== undefined) {
        lastName = stamp.name;
        stampBeforeNextStampWithName = undefined;
      }

      if (stampBeforeNextStampWithName === undefined) {
        for (let j = i + 1; j < stampsRaw.length; j++) {
          if (stampsRaw[j].name !== undefined) {
            stampBeforeNextStampWithName = stampsRaw[j - 1];
            break;
          }
        }
      }

      let emission = undefined;
      if (
        stamp.emission !== undefined &&
        stamp.emissionString &&
        !stamp.emissionString?.includes("; ")
      ) {
        emission = parseInt(
          stamp.emissionString.split(" - ")[1]?.replaceAll(" ", "")
        );
      }

      if (
        emission === undefined &&
        stampBeforeNextStampWithName?.emissionString !== undefined
      ) {
        stampBeforeNextStampWithName.emissionString
          ?.split("; ")
          .forEach((d) => {
            if (d.startsWith(stamp.id)) {
              if (d.includes(" - ")) {
                emission = parseInt(d.split(" - ")[1]?.replaceAll(" ", ""));
              }
            }
          });
      }

      let engraver = undefined;
      if (
        stamp.engraver !== undefined &&
        stamp.engraver &&
        !stamp.engraver?.includes("; ")
      ) {
        engraver = stamp.engraver;
      }

      if (
        engraver === undefined &&
        stampBeforeNextStampWithName?.engraver !== undefined
      ) {
        stampBeforeNextStampWithName.engraver?.split("; ").forEach((d) => {
          if (d.indexOf(stamp.id) !== -1) {
            engraver = d.split(" - ")[1]?.trim();
          }
        });
      }

      let designer = undefined;
      if (
        stamp.designer !== undefined &&
        stamp.designer &&
        !stamp.designer?.includes("; ")
      ) {
        designer = stamp.designer;
      }

      if (
        designer === undefined &&
        stampBeforeNextStampWithName?.designer !== undefined
      ) {
        stampBeforeNextStampWithName.designer?.split("; ").forEach((d) => {
          if (d.indexOf(stamp.id) !== -1) {
            designer = d.split(" - ")[1]?.trim();
          }
        });
      }

      let perforation = undefined;
      if (
        stamp.perforation !== undefined &&
        stamp.perforation &&
        !stamp.perforation?.includes("; ")
      ) {
        perforation = stamp.perforation;
      }

      if (
        perforation === undefined &&
        stampBeforeNextStampWithName?.perforation !== undefined
      ) {
        stampBeforeNextStampWithName.perforation?.split("; ").forEach((d) => {
          if (d.indexOf(stamp.id) !== -1) {
            perforation = d.split(" - ")[1]?.trim();
          }
        });
      }

      return {
        ...stamp,
        id: "cs" + stamp.id,
        name: lastName,
        _id: "cs" + stamp.id,
        designer: designer,
        perforation: perforation,
        engraver: engraver,
        printTechnique:
          stamp.printTechnique ??
          stampBeforeNextStampWithName?.printTechnique ??
          undefined,
        paper: stamp.paper ?? stampBeforeNextStampWithName?.paper ?? undefined,
        emission: emission ? emission : undefined,
        imgLocal: "/stamps/cs/" + stamp.id + ".jpg",
        emissionString: undefined,
        TF: undefined,
        FDC: undefined,
      };
    });
    console.log(JSON.stringify(stampsArray));
  }

  exportJSONImgToString() {
    const stampsRaw = lastStamps as Stamp[];

    const stampsMapped = stampsRaw.map((stamp) => ({
      ...stamp,
    }));
    let imgHtml = "";
    let themeTags: string[] = [];
    stampsMapped.forEach((stamp) => {
      imgHtml += `<img src="${stamp.img}" />`;
      if (stamp.themeTags) {
        stamp.themeTags.forEach((tag) => {
          if (!themeTags.includes(tag)) {
            themeTags.push(tag);
          }
        });
      }
    });
    console.log(imgHtml);
    console.log(themeTags);
  }

  async _requestStampsIdByTag(tag: string): Promise<string[]> {
    let stampsIds: string[] = [];
    if (cacheJson) {
      // Use a CORS proxy service to avoid CORS errors

      const url = `https://wearestamps.net/cache/theme_stamps_id/${tag}.json?t=${new Date().getTime()}`;
      const response = await fetch(url);
      const data = await response.json();
      stampsIds = data?.map((d: CacheThemeStampsId) => d.stamp_id);
    } else {
      const db = getFirestore();
      const colRefStamps2Tags = collection(db, DBTableStamps2Tags);
      const qStamps2Tags = query(colRefStamps2Tags, where("tag_id", "==", tag));

      await getDocs(qStamps2Tags).then((snapshot) => {
        snapshot.forEach((doc) => {
          stampsIds.push(doc.data().stamp_id);
        });
      });
    }
    return stampsIds;
  }

  async _requestStampsDetailByHash(hash: string) {
    if (cacheJson) {
      const urlHash = hash === "" ? "00" : hash;
      console.log("stat", urlHash, hash);
      const url = `https://wearestamps.net/cache/hash_stamps_detail/${urlHash}.json?t=${new Date().getTime()}`;
      const response = await fetch(url);
      const data = await response.json();
      this.handleLocalSnapshot(data);

      this.loading = false;

      return;
    } else {
      const dbLimitHash = this.hash === "" ? dbLimitHomepage : dbLimit;
      const db = getFirestore();
      const colRefStamps = collection(db, DBTableStamps);

      const q = query(
        colRefStamps,
        orderBy("_id", "asc"),
        orderBy("format", "asc"),
        orderBy("timestampField", "asc"),
        limit(dbLimitHash),
        startAt(this.hash || 0)
      );

      getDocs(q)
        .then((snapshot) => {
          this.handleSnapshot(snapshot);
          return;
        })
        .catch((error) => {
          console.error("Error getting documents stamps: ", error);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  async _requestStampsMap(tag: string) {
    console.log("AAAMAP 1", tag);
    if (cacheJson) {
      const url = `https://wearestamps.net/cache/theme_map_stamps_mini/${tag}.json?t=${new Date().getTime()}`;
      const response = await fetch(url);
      const dataStampsMap = await response.json();

      this.handleLocalSnapshotMini(dataStampsMap);
      return;
    }
  }

  async _requestStampsDetail(
    tag: string,
    tagStampsIds: string[],
    pageIndex: number
  ) {
    if (cacheJson) {
      const url = `https://wearestamps.net/cache/theme_stamps_detail/${tag}_${
        pageIndex + 1
      }.json?t=${new Date().getTime()}`;
      const response = await fetch(url);
      const data = await response.json();
      this.handleLocalSnapshot(data);
      this.loading = false;
      return;
    } else {
      let q;
      const db = getFirestore();
      const colRefStamps = collection(db, DBTableStamps);

      console.log("this.stampsTagsIds", this.stampsTagsIds);
      q = query(
        colRefStamps,
        where(
          "_id",
          "in",
          tagStampsIds.slice(
            pageIndex * dbLimitThemes,
            (pageIndex + 1) * dbLimitThemes
          )
        ),
        where("format", "==", "Stamp"),
        orderBy("timestampField", "asc")
      );
      console.log("q", q);

      getDocs(q)
        .then((snapshot) => {
          this.handleSnapshot(snapshot);
          return;
        })
        .catch((error) => {
          console.error("Error getting documents stamps: ", error);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  async operationTags() {
    const db = getFirestore();
    const colRef = collection(db, DBTableTags);

    try {
      const querySnapshot = await getDocs(colRef);

      const batch = writeBatch(db);

      querySnapshot.forEach((doc) => {
        const docRef: DocumentReference = doc.ref;

        batch.update(docRef, {
          count: 0,
        });
      });

      await batch.commit();

      console.log("Ids copied to _id successfully.");
    } catch (error) {
      console.error("Error copying ids:", error);
    }
  }

  async onCopyIdTo_IdClick() {
    const db = getFirestore();
    const colRef = collection(db, DBTableStamps);

    try {
      const querySnapshot = await getDocs(colRef);
      const batch = writeBatch(db);
      querySnapshot.forEach((doc) => {
        const docRef: DocumentReference = doc.ref;
        if (doc.data().country && doc.data().country === "in") {
          batch.update(docRef, {
            imgLocal: doc.data().imgLocal.replace("in/Stamps2013/", "in/"),
          });
        }
      });
      await batch.commit();
      console.log("Ids copied to _id successfully.");
    } catch (error) {
      console.error("Error copying ids:", error);
    }
  }

  async onDevBiometa(id: string, bioName: string, bioTag: string) {
    console.log("onDevBiometa", id, bioName, bioTag);
  }

  async onDevGeolocation(id: string, geoLatLng: string, geoTag: string) {
    const db = getFirestore();
    const geoLat = geoLatLng.split(",")[0];
    const geoLng = geoLatLng.split(",")[1];
    const geoLocation = new GeoPoint(Number(geoLat), Number(geoLng));
    console.log("geoLocationXXXXX", id, geoLocation);

    const colRef = collection(db, DBTableStamps);
    const q = query(colRef, where("_id", "==", id));
    const querySnapshot = await getDocs(q);

    const batch = writeBatch(db);
    querySnapshot.forEach((doc) => {
      const docRef: DocumentReference = doc.ref;
      const geometa = doc.data().geometa || [];
      geometa.push({
        location: geoLocation,
        type: geoTag,
        name: doc.data().name,
      });
      batch.update(docRef, {
        geometa: geometa,
      });
    });

    await batch.commit();

    console.log("geoLocation updated successfully.");
  }

  async onStampNameUpdate(name: string, id?: string) {
    console.log("onStampNameUpdate", name, id);

    const newName = name.trim();

    if (!id || newName === "") {
      console.error("Invalid ID or name.");
      return; // Exit the function if ID is missing or name is empty.
    }

    try {
      const db = getFirestore();
      const colRef = collection(db, DBTableStamps);

      const q = query(colRef, where("_id", "==", id));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.size === 0) {
        console.log("Document not found in Firestore:", id);
        return;
      }

      const batch = writeBatch(db);
      querySnapshot.forEach((doc) => {
        const docRef: DocumentReference = doc.ref;
        batch.update(docRef, {
          nameEn: newName,
        });
      });

      await batch.commit();
    } catch (error) {
      console.error("Error updating name:", error);
    }
  }

  /**
   * Add all sTags to Firestore database.
   */
  async addTimestampField() {
    const db = getFirestore();

    const sTags = [
      "A-B Clips",
      "Accountants",
      "Actors",
      "Administrative offices",
      "Admirals",
      "Advertisements",
      "Aerobics",
      "Agricultural Machinery",
      "Agriculture",
      "Aid to Refugees",
      "AIDS",
      "Air Balloons",
      "Air Forces",
      "Aircraft",
      "Airlines",
      "Airports",
      "Algae",
      "Altar",
      "American Football",
      "Amphibians",
      "Amusement Parks",
      "Ancient States",
      "Angels",
      "Angling",
      "Animals (Fauna)",
      "Anniversaries and Jubilees",
      "Anteaters",
      "Antelopes",
      "Anthropology",
      "Anti-drugs",
      "Antiracism",
      "Apartment Buildings",
      "Apes",
      "Apostles",
      "Aquatic Plants",
      "Aquatic Sports",
      "Aqueducts",
      "Archaeologists",
      "Archaeology",
      "Archery",
      "Architects",
      "Architecture",
      "Aristocrats",
      "Armors",
      "Army",
      "Arrows",
      "Art",
      "Art Nouveau",
      "Art of Cooking",
      "Artillery",
      "Artists",
      "Astronauts",
      "Astronomers",
      "Astronomy",
      "Athletes",
      "Athletics",
      "Aurora Borealis / Aurora Australis",
      "Australian Football",
      "Authors",
      "Automobile Industry",
      "Autumn",
      "Aviation",
      "Axes",
      "Backpacks",
      "Badgers",
      "Badminton",
      "Bags",
      "Ballet",
      "Bank Cards",
      "Bankers",
      "Banknotes",
      "Banks",
      "Baroque",
      "Barrels",
      "Baseball",
      "Basketball",
      "Bats",
      "Battle",
      "Beach Volleyball",
      "Beaches",
      "Beacons",
      "Bears",
      "Beavers",
      "Beer",
      "Beer Coasters",
      "Bees",
      "Beetles",
      "Bells",
      "Beverages",
      "Biathlon",
      "Biblical Accounts",
      "Bicycles",
      "Billiards",
      "Biologists",
      "Birds",
      "Birds of Prey",
      "Bishops",
      "Bison",
      "Bobsleigh",
      "Book Shops",
      "Books",
      "Botanists",
      "Bottles",
      "Boules",
      "Boxing",
      "Braille (Writing System)",
      "Breweries",
      "Bridges",
      "British Commonwealth",
      "Broadcasting Corporations",
      "Brokers",
      "Bronze Age",
      "Buddhism",
      "Buffaloes",
      "Building Industry",
      "Buildings",
      "Bullfighting",
      "Bulls and Cows",
      "Buses",
      "Businessman",
      "Butterflies and Moths",
      "C.E.P.T. / Europe",
      "Cable Cars",
      "Cacti",
      "Caduceus",
      "Cafés",
      "Cakes",
      "Calendars",
      "Camels",
      "Cameras",
      "Canals",
      "Cancer (Disease)",
      "Candy",
      "Canoeing",
      "Cardinals",
      "Carnival",
      "Carriages",
      "Carrier-pigeons",
      "Cars",
      "Cartography",
      "Cartoons",
      "Carving Items",
      "Casinos",
      "Castles",
      "Caterpillars",
      "Cats",
      "Cattle",
      "Caves",
      "Celestial Bodies",
      "Cemeteries",
      "Censored",
      "Census of Population",
      "Ceramics",
      "Cereals",
      "Chains",
      "Chamber of Commerce",
      "Chameleons",
      "Charity",
      "Cheetahs",
      "Chemical Industry",
      "Chemistry",
      "Chemists",
      "Chess",
      "Children",
      "Children's Play",
      "Chinese New Year",
      "Chinese Zodiac",
      "Choreographers",
      "Christian",
      "Christmas",
      "Churches - Cathedrals - Basilicas - Chapels",
      "Cinema",
      "Circus",
      "City Halls",
      "City Walls",
      "Civil engineering",
      "Clergy",
      "Clocks",
      "Clothing",
      "Clouds",
      "Clowns",
      "Clubs of Phonecard Collectors",
      "Clubs of Stamp Collectors",
      "Coastal Areas",
      "Coats of Arms",
      "Coffee",
      "Coins",
      "Colonisation",
      "Comets",
      "Comics",
      "Commemoration",
      "Communication",
      "Communism",
      "Compasses",
      "Composers",
      "Computer Sciences",
      "Computers",
      "Concentration Camps",
      "Concert Buildings",
      "Conductors",
      "Conferences",
      "Congress Centres",
      "Constellations",
      "Container Ships",
      "Cookies",
      "Corals and Sponges",
      "Cornucopias (Horn of Plenty)",
      "Cosmetic Products",
      "Cougars",
      "Council of Europe",
      "Countess (Lady)",
      "Counts (Lord)",
      "COVID",
      "Crabs",
      "Crafts",
      "Cranes (Birds)",
      "Cranes (Machines)",
      "Crescent (Moon)",
      "Cricket",
      "Crocodiles",
      "Crops",
      "Cross-country Skiing",
      "Crosses",
      "Crowns and Coronets",
      "Cruise Ships",
      "Crustaceans",
      "Cryptostamps",
      "Culture",
      "Curling",
      "Customs",
      "Cutlery",
      "Cycling",
      "Dams",
      "Dance",
      "Darts",
      "Deer",
      "Dental Surgery",
      "Deserts",
      "Detectives",
      "Development Aid",
      "Diagrams",
      "Dictators",
      "Dinosaurs",
      "Diplomacy",
      "Diplomats",
      "Directors",
      "Disabled",
      "Disasters",
      "Discoverers",
      "Discoveries",
      "Discus-throwing",
      "Diseases",
      "Disney",
      "Distilled Beverages",
      "Diving (Scuba-Snorkelling)",
      "Diving (Sport of jumping)",
      "Documents",
      "Dogs",
      "Dolphins",
      "Donkeys",
      "Dormice",
      "Dragonflies",
      "Dragons",
      "Drawings",
      "Drinkware",
      "Duchess",
      "Ducks",
      "Dukes",
      "Dunes",
      "E.F.T.A.",
      "Eagles",
      "Easter",
      "Economists",
      "Economy",
      "Education",
      "Eggs",
      "Elections",
      "Electricity",
      "Elephants",
      "Elks",
      "Elvis Presley",
      "Emancipation",
      "Emigration",
      "Emirs",
      "Emperors",
      "Employment agencies",
      "Empress",
      "Energy",
      "Energy Conservation",
      "Engineers",
      "Engravers",
      "Engravings",
      "Entertainers",
      "Environment Protection",
      "Equestrianism and horse riding",
      "Eroticism",
      "Euro",
      "Euromed",
      "European Cultural Capital",
      "European Parliament",
      "European Union",
      "European Years",
      "Exchange Buildings",
      "Exhibition Buildings",
      "Expeditions",
      "Explorers",
      "Expositions",
      "F.A.O.",
      "Fables",
      "Faces",
      "Factories",
      "Fairs",
      "Fairy Tales",
      "Falcons",
      "Families",
      "Famous People",
      "Farmhouses",
      "Fashion",
      "Fashion Models",
      "Fast Foods",
      "Feasts",
      "Feathers",
      "Fencing",
      "Ferns",
      "Ferries",
      "Festivals",
      "Figure-skate",
      "Filling Stations",
      "Finance",
      "Fire and Flames",
      "Fire-brigade",
      "Firework",
      "First World War",
      "Fishery",
      "Fishes",
      "Fishing-boats",
      "Flags",
      "Flamingos",
      "Floodings",
      "Flowers",
      "Folklore",
      "Food",
      "Football (Soccer)",
      "Forestry",
      "Forests",
      "Formula One",
      "Fortresses / Strongholds",
      "Fossils",
      "Fountains",
      "Foxes",
      "Frames",
      "Freeclimbing",
      "Freedom Fighters",
      "Freedom from hunger",
      "Freemasons / Masonic Orders",
      "French Revolution",
      "Frescos",
      "Frogs",
      "Fruits and Berries",
      "Funeral services",
      "Furniture",
      "Gaelic Football",
      "Gallic Era",
      "Gambling",
      "Gardens",
      "Gastronomy",
      "Gates",
      "Gears - Cogs Wheels",
      "Geese",
      "Gemstones",
      "Genealogy",
      "Generals",
      "Geodesy and Surveying",
      "Geographers",
      "Geography",
      "Geologists",
      "Geology",
      "Geometric Shapes",
      "Geysers",
      "Giraffes",
      "Glaciers",
      "Glass and Earthenware",
      "Gliding",
      "Globes",
      "Goats",
      "Gods and goddesses",
      "Golden Objects",
      "Golf",
      "Gothic",
      "Government Buildings",
      "Government Departments",
      "Graffiti",
      "Grapes",
      "Graphic Artists",
      "Greetings",
      "Gulls",
      "Gymnastics",
      "Halloween",
      "Hammer Throw",
      "Hamsters",
      "Handball",
      "Hands",
      "Hanggliding",
      "Hardware Stores",
      "Hares",
      "Harps",
      "Harry Potter",
      "Headgear",
      "Heads of State",
      "Heads of State (Military)",
      "Health Resorts",
      "Healthcare",
      "Hearts",
      "Heavy Equipment",
      "Hedgehogs",
      "Helicopters",
      "Helmets",
      "Heraldic Animals",
      "Herbs and Spices",
      "Heroes",
      "Herons",
      "High Jump",
      "Hiking",
      "Hippopotamuses",
      "Historians",
      "Historic Sites",
      "Hockey",
      "Holograms",
      "Holy Year",
      "Home Appliances",
      "Honeycombs",
      "Horns",
      "Horses",
      "Hospitals",
      "Hotels",
      "Houses",
      "Hovercrafts",
      "Human Rights",
      "Humanists",
      "Hummingbirds",
      "Humour",
      "Hunting",
      "Hurdling",
      "Hyenas",
      "Ice",
      "Ice Age",
      "Ice Creams",
      "Ice Hockey",
      "Ice Sculptures",
      "Ice-breakers",
      "Illustrators",
      "Immigrants",
      "Independency Activists",
      "Industry",
      "Infant Animals",
      "Infants",
      "Insects",
      "Instruments",
      "Insurances",
      "Intelligence Agencies",
      "Interior Design",
      "International Years",
      "Internet",
      "Inventions",
      "Inventors",
      "Ironwork",
      "Irregular-Shaped Stamps",
      "Islands",
      "Jaguars",
      "Jars",
      "Javelin Throw",
      "Jellyfish",
      "Jewelery",
      "Joint Issues",
      "Journalism",
      "Journalists",
      "Judo",
      "Jurists",
      "Kangaroos",
      "Karate",
      "Kayaking",
      "Keyplate Type",
      "Keys",
      "Kingfishers",
      "Kings",
      "Kites",
      "Knights",
      "Koalas",
      "Laboratories",
      "Labour Organisations",
      "Lakes",
      "Lamps and Candles",
      "Landscapes",
      "Languages",
      "Lanterns",
      "Lawyers - Advocates",
      "Leaves",
      "Legends",
      "Leopards",
      "Letters (Alphabet)",
      "Letters (Mail)",
      "LGBT",
      "Liberations",
      "Libraries",
      "Lichens",
      "Lifeboats",
      "Lift Truck",
      "Lighthouses",
      "Lilies (Flower)",
      "Linguists",
      "Lions",
      "Lions (Org.)",
      "Literary People (Poets and Writers)",
      "Literature",
      "Lizards",
      "Llamas",
      "Locks",
      "Locomotives",
      "Logos",
      "Long Jump",
      "Lotteries",
      "Lotus (Flower)",
      "Love",
      "Luge",
      "Lutheran",
      "Lynx",
      "Magazines",
      "Magic",
      "Magicians",
      "Mailboats",
      "Mailboxes",
      "Mailcoaches",
      "Malaria",
      "Mammals",
      "Maps",
      "Marilyn Monroe",
      "Marionettes",
      "Markets",
      "Marmots",
      "Marsupials",
      "Martens (mammal)",
      "Martial Arts",
      "Mascots",
      "Masks",
      "Mathematicians",
      "Mathematics",
      "Mausolea",
      "Mayors",
      "Meadows",
      "Measuring Instruments",
      "Medals and Marks of Honour",
      "Medical Science",
      "Medicines",
      "Men",
      "Mermaids",
      "Metallurgical Industry",
      "Metals",
      "Metalworking",
      "Meteorites",
      "Meteorology",
      "Meterological Instruments",
      "Mice",
      "Microscopes",
      "Middle Ages",
      "Military Forces",
      "Military Officers",
      "Millennium",
      "Mills",
      "Mineralogists",
      "Minerals",
      "Miners",
      "Miniatures",
      "Mining",
      "Mobile Phones",
      "Models",
      "Moles",
      "Molluscs",
      "Monasteries and Convents",
      "Monkeys",
      "Monks",
      "Monograms",
      "Monuments",
      "Moon",
      "Moon Landing",
      "Mosaic",
      "Mosques",
      "Mosquitos",
      "Mosses",
      "Motor Racing",
      "Motorboats",
      "Motorcycles",
      "Mouflons",
      "Mountain Climbing",
      "Mountains",
      "Movies",
      "Museums",
      "Mushrooms",
      "Music",
      "Musical Comedies",
      "Musical Instruments",
      "Musicians",
      "Muslim",
      "Mythology",
      "N.A.T.O.",
      "NASCAR",
      "National / Independence Days",
      "National Anthems",
      "National Guards",
      "National Parks",
      "Native Americans (Indians)",
      "Natural Disasters",
      "Naturalists",
      "Naval Battles",
      "Navies",
      "Navigators",
      "Neurologists",
      "New Year",
      "Newspapers",
      "Night Views",
      "Nobel Laureates",
      "Norden",
      "Notaries",
      "Nudes",
      "Numbers",
      "Numismatic Exhibitions",
      "Numismatics",
      "Nuts",
      "Obelisks",
      "Observatories",
      "Occupational Safety and Health",
      "Octopuses",
      "Offices",
      "Oil Rigs",
      "Old Age",
      "Olives",
      "Olympic Games",
      "Omnibus Issues",
      "Oncologist",
      "Oncology",
      "Opera Houses",
      "Operas",
      "Optical Instruments",
      "Opticians",
      "Orchestras",
      "Orchids",
      "Order of Hospitallers of Malta",
      "Organist",
      "Organizations",
      "Orienteering",
      "Ostriches",
      "Otters",
      "Outer Space",
      "Owls",
      "Pagodas",
      "Painters",
      "Paintings",
      "Palaces",
      "Palm-trees",
      "Pandas",
      "Parachute Jumping",
      "Paragliding",
      "Paralympics",
      "Parks",
      "Parliaments",
      "Parrots",
      "Passenger-ships",
      "Pastries / Bakery products",
      "Peace Doves",
      "Peacekeeping Force",
      "Peacocks",
      "Pelicans",
      "Penguins",
      "People",
      "Perfumes",
      "Personal Water Crafts",
      "Petrochemical Industries",
      "Pharaohs",
      "Pharmacies",
      "Pheasants",
      "Philanthropist",
      "Philatelic Exhibitions",
      "Philately",
      "Philosophers",
      "Philosophy",
      "Phone Booths",
      "Phonecard Exhibitions",
      "Phonecards",
      "Photographers",
      "Photographs",
      "Photography",
      "Physicians",
      "Physicists",
      "Physics",
      "Pigeons",
      "Pigs",
      "Pilots",
      "Pirates",
      "Planets",
      "Plantations",
      "Plants (Flora)",
      "Play (at) ninepins",
      "Playing Cards",
      "Playwrights",
      "Poems",
      "Polar Bears",
      "Pole Vaulting",
      "Polecats",
      "Police",
      "Political parties",
      "Politicians",
      "Politics",
      "Popes",
      "Poppies (Flower)",
      "Porcupines",
      "Portraits",
      "Ports",
      "Post Horns",
      "Post Offices",
      "Postal History",
      "Postal Services",
      "Postal Traffic",
      "Postal Unions",
      "Postcards",
      "Postcodes",
      "Postcrossing",
      "Postmarks",
      "Postmasters",
      "Postmen",
      "Poultry",
      "Power Plants",
      "Prehistoric Animals",
      "Prehistory",
      "Presents / Gifts",
      "Prime Ministers",
      "Princes",
      "Princesses",
      "Printing presses",
      "Prisons",
      "Professions",
      "Professors",
      "Promotional Posters",
      "Protestant",
      "Proverbs and Quotations",
      "Psychiatrists",
      "Psychiatry",
      "Psychologists",
      "Public Transport",
      "Public Utility Companies",
      "Publishers",
      "Puffins",
      "Pumps",
      "Puppets",
      "Purses",
      "Puzzles",
      "Pyramids",
      "Queens",
      "R.C.C.",
      "Rabbits",
      "Raccoons",
      "Racing Cars",
      "Racing Drivers",
      "Radio",
      "Rafting",
      "Rafts",
      "Railways",
      "Rain",
      "Rainbows",
      "Rainforest",
      "Rats",
      "Rays",
      "Rays (Fishes)",
      "Red Cross and Red Crescent",
      "Reformers",
      "Refugees",
      "Religion",
      "Renaissance",
      "Reprints",
      "Reptiles",
      "Rescue Services",
      "Rescue Squads",
      "Restaurants",
      "Revolutionaries",
      "Revolutions",
      "Rhinoceroses",
      "Ribbons",
      "Rivers",
      "Road Safety",
      "Road Signs",
      "Roads",
      "Rock Art and Cave Paintings",
      "Rockets",
      "Rocks",
      "Rococo",
      "Rodents",
      "Roller-skate",
      "Roller-skate Hockey",
      "Roman Empire",
      "Romanesque",
      "Roosters and Chickens",
      "Roses",
      "Rotary",
      "Round Stamps",
      "Rowing",
      "Rowing Boats",
      "Royalty",
      "Rugby",
      "Ruins",
      "Running",
      "S.E.P.A.C.",
      "Sailing",
      "Sailing Ships",
      "Sailplanes",
      "Saints",
      "Salamander",
      "Salvation Army",
      "Santa Claus",
      "Satellite Dishes",
      "Satellites",
      "Scales (balance)",
      "School projects",
      "Schools",
      "Science",
      "Science Fiction",
      "Scientists",
      "Scorpions",
      "Scouting",
      "Sculptors",
      "Sculptures",
      "Sea",
      "Sea (Marine) Mammals",
      "Sea Life",
      "Seahorses",
      "Seals (Animals)",
      "Seals (Emblems)",
      "Seasons",
      "Second World War",
      "Shahs",
      "Sharks",
      "Sheep",
      "Shells",
      "Shipbuilding",
      "Ships",
      "Shipwrecks",
      "Shoes",
      "Shooting Sports",
      "Shopping",
      "Shops",
      "Shot-put",
      "Signatures - Autographs",
      "Signs",
      "Silver Objects",
      "Singers",
      "Skateboard",
      "Skating",
      "Skeet Shooting",
      "Skeletons",
      "Ski-jump",
      "Skiing",
      "Slalom",
      "Sledges",
      "Snails and Slugs",
      "Snakes",
      "Snow",
      "Snowboarding",
      "Snowmen",
      "Social Critics",
      "Sociologists",
      "Soda",
      "Space Traveling",
      "Spacecraft",
      "Special Materials",
      "Special Occasions",
      "Special Surfaces",
      "Speed Skating",
      "Spiders",
      "Sports",
      "Sports Clubs",
      "Spring",
      "Squash",
      "Squirrels",
      "Stadiums",
      "Stained-glass",
      "Stamp Anniversaries",
      "Stamp Collectors",
      "Stamp Day",
      "Stamps",
      "Star Trek",
      "Star Wars",
      "Starfish",
      "Stars",
      "Statesmen",
      "Stationery",
      "Stations",
      "Statues",
      "Steam Traction",
      "Steamers",
      "Stone Age",
      "Stones",
      "Storks",
      "Storms",
      "Streams",
      "Streetball",
      "Students",
      "Stylized Animals",
      "Stylized Figures / Persons",
      "Stylized Objects",
      "Stylized Plants",
      "Submarines",
      "Suitcases",
      "Suits and Costumes",
      "Sultan",
      "Summer",
      "Sun",
      "Sundials",
      "Sunflowers",
      "Sunsets / Sunrises",
      "Surfing",
      "Suricates",
      "Swans",
      "Swimming",
      "Swords",
      "Symbols",
      "Synagogues",
      "Synchronized Swimming",
      "T.T. Races",
      "Table Tennis",
      "Tableware",
      "Taekwondo",
      "Tankers",
      "Tanks",
      "Taxis",
      "Tea",
      "Teachers",
      "Technicians",
      "Technology",
      "Teddy Bears",
      "Telecommunication",
      "Telegraphy",
      "Telephone Directories",
      "Telephones",
      "Telephony",
      "Telescopes",
      "Television",
      "Television Towers",
      "Temples",
      "Tennis",
      "Textile",
      "Textile Industry",
      "The Beatles",
      "Theatre",
      "Theologians",
      "Thunderstorms",
      "Tigers",
      "Tobacco",
      "Tobacco Smoking",
      "Tokens",
      "Tools",
      "Torches",
      "Toucans",
      "Tourism",
      "Towers",
      "Town Squares",
      "Townscapes / City Views",
      "Toys",
      "Tractors",
      "Trade",
      "Traditions",
      "Traffic Lights",
      "Trams",
      "Transparent",
      "Transport",
      "Travel Agencies",
      "Treaties",
      "Trees",
      "Triangle Stamps",
      "Triathlon",
      "Tribal Chiefs and Caciques",
      "Tridents",
      "Trophies",
      "Trucks",
      "Tuberculosis / TBC",
      "Tulips",
      "Tunnels",
      "Turtles",
      "Tusks / Fangs",
      "Typewriters",
      "Tyres",
      "U.I.T.",
      "U.N.H.C.R.",
      "U.N.O.",
      "U.P.A.E.P.",
      "U.P.U.",
      "Umbrellas",
      "Undergarments",
      "Undergrounds (Subways)",
      "UNESCO",
      "UNESCO World Heritage Sites",
      "UNICEF",
      "Uniforms",
      "Universities",
      "Uprising",
      "Utilities",
      "Valentine's Day",
      "Vegetables",
      "Vehicles",
      "Victorian Age",
      "Video Games",
      "Viking Era",
      "Viticulture",
      "Volcanos",
      "Volleyball",
      "Vultures",
      "W.H.O.",
      "W.T.O.",
      "Walking sticks",
      "Walls",
      "Walruses",
      "Warships",
      "Waste",
      "Watches",
      "Water",
      "Water Skiing",
      "Water Towers",
      "Water works",
      "Water-polo",
      "Waterfalls",
      "Weapons",
      "Weasels",
      "Weather",
      "Weddings",
      "Weightlifting",
      "Whales",
      "Wheelchair Driving",
      "Wheels",
      "Wild Cats",
      "Windmills",
      "Windows",
      "Wine",
      "Winter",
      "Winter Sports",
      "Wolves",
      "Women",
      "Woodpeckers",
      "World Exhibitions",
      "World Records",
      "Wreaths",
      "Wrestling",
      "WWF",
      "Youth Groups",
      "Youth Hostels",
      "Zebras",
      "Zeppelins",
      "Zodiac",
      "Zoos",
    ];

    try {
      const batch = writeBatch(db);
      sTags.forEach((tag) => {
        const docRef = doc(collection(db, "xtags"));
        batch.set(docRef, {
          label: tag,
          tag_id: tag.replaceAll(" / ", " ").replaceAll(" ", "_").toLowerCase(),
          count: 0,
        });
      });

      await batch.commit();

      console.log("sTags added to Firestore successfully.");
    } catch (error) {
      console.error("Error adding tag fields:", error);
    }
  }

  async addTimestampFieldx() {
    const db = getFirestore();
    const colRef = collection(db, DBTableStamps);

    try {
      const querySnapshot = await getDocs(colRef);

      const batch = writeBatch(db);

      querySnapshot.forEach((doc) => {
        const docRef: DocumentReference = doc.ref;
        // const randomString = generateRandomString(12); // Adjust the length as needed

        // if (
        //     doc.data().timestampField === undefined &&
        //     doc.data().countryName !== ""
        //   ) {
        //     batch.update(docRef, {
        //       timestampField: randomString,
        //     });

        //     console.log("add timestampField", doc.data().id, randomString);
        //   }

        if (
          doc.data().countryNameCode === "korea_south" &&
          doc.data().format === "StampReprint"
        ) {
          batch.update(docRef, {
            format: "Stamp",
          });
          console.log("update stamp reprint", doc.data().id);
        }
      });

      await batch.commit();

      console.log(
        "Timestamp Reprint edited to random strings for all documents successfully."
      );
    } catch (error) {
      console.error("Error editing timestamp fields:", error);
    }
  }

  // function replace value in field in Firebase if it has a specific value
  // inputs values are hardcoded
  // inputs are collection, column, old value, new value
  async onRenameColumnClick() {
    let collectionName: string = "stamps2tags";
    let fieldName: string = "tag_id";
    let oldValue: string = "heraldics";
    let newValue: string = "heraldry";
    // let collectionName: string = "stamps2tags";
    // let fieldName: string = "tag_id";
    // let oldValue: string = "famous_people";
    // let newValue: string = "people";
    const db = getFirestore();
    const colRef = collection(db, collectionName);

    try {
      const querySnapshot = await getDocs(colRef); // Wait for the querySnapshot

      const batch = writeBatch(db);

      querySnapshot.forEach((doc) => {
        const docRef: DocumentReference = doc.ref;
        const data = doc.data();

        // Check if the old field exists before updating
        if (data[fieldName] === oldValue) {
          batch.update(docRef, {
            [fieldName]: newValue,
          });
        } else {
          console.warn(
            `Document ${doc.id} does not have the field ${fieldName}. Skipping.`
          );
        }
      });

      await batch.commit(); // Wait for the batch to commit

      console.log("Field renamed successfully.");
    } catch (error) {
      console.error("Error renaming field:", error);
    }
  }

  // convert example: 15 August 2023 ---> 2023-08-15
  converDateToDbFormat(date: string) {
    console.log("date to convert", date);
    const dateParts = date.split(" ");
    const day = dateParts[0];
    const month = dateParts[1];
    const year = dateParts[2];
    const monthNumber = this.getMonthNumber(month);
    const dbDate = `${year}-${monthNumber}-${day}`;
    return dbDate;
  }

  // convert example: 2023-08-15 ---> 15 August 2023
  convertDateToDisplayFormat(date: string) {
    console.log("date to convert", date);
    const dateParts = date.split("-");
    const day = dateParts[2];
    const month = this.getMonthName(dateParts[1]);
    const year = dateParts[0];
    const displayDate = `${day} ${month} ${year}`;
    return displayDate;
  }

  getMonthName(month: string): string {
    switch (month) {
      case "01":
        return "January";
      case "02":
        return "February";
      case "03":
        return "March";
      case "04":
        return "April";
      case "05":
        return "May";
      case "06":
        return "June";
      case "07":
        return "July";
      case "08":
        return "August";
      case "09":
        return "September";
      case "10":
        return "October";
      case "11":
        return "November";
      case "12":
        return "December";
      default:
        return "";
    }
  }

  getMonthNumber(month: string): string {
    switch (month) {
      case "January":
        return "01";
      case "February":
        return "02";
      case "March":
        return "03";
      case "April":
        return "04";
      case "May":
        return "05";
      case "June":
        return "06";
      case "July":
        return "07";
      case "August":
        return "08";
      case "September":
        return "09";
      case "October":
        return "10";
      case "November":
        return "11";
      case "December":
        return "12";
      default:
        return "";
    }
  }
}

// Function to generate a random string of specified length
export function generateRandomString(length: number): string {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function makeTagId(label: string): string {
  return label.replaceAll(" / ", " ").replaceAll(" ", "_").toLowerCase();
}
