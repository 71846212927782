import { Container, Grid, Button, Typography } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

interface Props {
  columns: number;
  withAboutUs: boolean;
  withCountries: boolean;
}

const Footer = (props: Props) => {
  const navigate = useNavigate();
  return (
    <footer className="footer">
      <Box>
        <Box>
          {props.withAboutUs && (
            <Box
              sx={{
                padding: "32px 0 48px",
                lineHeight: "1.4",
              }}
            >
              <Typography variant="h2" className="main-heading">
                We love stamps, we are stamps
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "60px",
                }}
              >
                <Box>
                  The web portal dedicated to postage stamps from all over the
                  world offers a vast collection of philatelic treasures. Stamp
                  enthusiasts and history buffs can explore stamps originating
                  from countries spanning the globe, each with its unique
                  historical and cultural significance. These postage stamps
                  vary in terms of release dates, with some dating back to the
                  19th century, serving as a fascinating window into the past.
                  One can delve into the intricacies of perforation, observing
                  how the tiny holes around the stamps were designed to
                  facilitate separation with precision. The stamps are
                  meticulously grouped by tags, making it easy for collectors to
                  navigate and discover their areas of interest.
                </Box>
                <Box>
                  Discover the evolution of stamp designs and printing
                  techniques, reflecting the changing artistic and technological
                  trends of each era. Gain insights into the historical events,
                  important personalities, and landmarks depicted on these
                  miniature works of art. Explore the influence of culture and
                  traditions on stamp design, with each nation's stamps
                  reflecting its unique identity. Learn about the rare and
                  limited-edition stamps that have become valuable collector's
                  items over time. Whether you're an experienced philatelist or
                  a curious beginner, this web portal provides a wealth of
                  information and a visually stunning journey through the world
                  of postage stamps.
                </Box>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              padding: "72px 0",
            }}
          >
            <Container>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Typography variant="h6">WeAreStamps</Typography>
                  <Typography variant="body2">
                    © {new Date().getFullYear()} WeAreStamps.net
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ marginTop: "0px" }}>
                    <a href="/privacy-policy.html">
                      <Typography>
                        <Button
                          className="law"
                          variant="text"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/privacy-policy`);
                          }}
                        >
                          Privacy Policy
                        </Button>
                      </Typography>
                    </a>
                  </div>
                  <div>
                    <a href="/terms.html">
                      <Typography>
                        <Button
                          className="law"
                          variant="text"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/terms`);
                          }}
                        >
                          Terms
                        </Button>
                      </Typography>
                    </a>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      gap: "10px",
                    }}
                  >
                    <a href="https://twitter.com/StampsWe20213">
                      <Button
                        className="social"
                        sx={{ height: "64px" }}
                        aria-label="Twitter"
                      >
                        <TwitterIcon />
                      </Button>
                    </a>
                    <a href="https://www.instagram.com/_we_are_stamps_/">
                      <Button
                        className="social"
                        sx={{ height: "64px" }}
                        aria-label="Instagram"
                      >
                        <InstagramIcon />
                      </Button>
                    </a>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
