import { Box, Grid } from "@mui/material";
import { Fragment } from "react";

interface Props {
  label: string;
  value: {
    name: string;
    type: string;
    location: { _lat: number; _long: number };
  }[];
}

function AttributeGeometa(props: Props) {
  return (
    <>
      <Grid className="attribute" container wrap="wrap">
        {props.label && (
          <Grid item>
            <Box className="attribute-label">{props.label}</Box>
          </Grid>
        )}
        <Grid item>
          <Box className="attribute-value">
            {props.value &&
              props.value.map((line, index) => (
                <Fragment key={index}>
                  <div>
                    {/* First letter uppercase type */}
                    {line.type.charAt(0).toUpperCase() +
                      line.type.slice(1)}: {line.location._lat.toFixed(5)}°{" "}
                    {line.location._lat > 0 ? "N" : "S"}{" "}
                    {line.location._long.toFixed(5)}°{" "}
                    {line.location._long > 0 ? "E" : "W"}
                  </div>
                </Fragment>
              ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default AttributeGeometa;
